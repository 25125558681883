import { PageItem } from "../page-admin/page-item.model";
import { MenuItemType } from "./menuitem.type.enum";

export class MenuItem {
    public id: number;
    public displayText: string;
    public itemOrder: number;
    public iconUrl: string;
    public parentId?: number | null;
    public pageItemId?: number;
    public routePath: string;
    public pageItem?: PageItem | null;
    public childMenuItems: MenuItem[]
    public menuItemType:MenuItemType;

    public icon?: string;
    public label?: string;
    public text?: string;
    public alert: boolean = false;
    constructor() {
        this.childMenuItems = [];
        this.itemOrder = 1;
        this.pageItem = new PageItem();
    }
}
