export class AuthenticatedModel {
  public id: number;
  public userName: string;
  public name: string;
  public surname: string;
  public profilePicture: string;
  public email: string;
  public clientId: number;
  public profilePictureUrl: string;
  public profilePictureName: string;
  public roles: number[] = [];
  public isSsoUser: boolean;
}
