import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimePickerComponent } from './cron-time-picker.component';
import { CronMakerComponent } from './cron-maker.component';
import { TabsModule } from "ngx-bootstrap/tabs";
import { NgxSelectModule } from 'ngx-select-ex';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule.forRoot(),
    NgxSelectModule
  ],
  exports: [TimePickerComponent, CronMakerComponent],
  declarations: [TimePickerComponent, CronMakerComponent]
})
export class CronMakerModule { }