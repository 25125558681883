<a class="nav-link dropdown-toggle dropdown-toggle-nocaret" dropdownToggle (click)="notificationClicked()">
  <em class="icon-bell"></em>
  <span class="{{notificationsCount > 0 ? 'badge badge-danger' : 'badge'}}">{{notificationsCount}}</span>
</a>
<!-- START Dropdown menu-->
<div *dropdownMenu class="dropdown-menu dropdown-menu-right animated flipInX">
  <div class="dropdown-item" style="cursor: pointer !important">
    <!-- START list group-->
    <div class="list-group">
      <div *ngIf="notifications == null" class="list-group-item list-group-item-action">
        <div class="media">
          <div class="align-self-start mr-2">
            <em class="fa fa-bell-slash-o fa-2x text-success"></em>
          </div>
          <div class="media-body">
            <p class="m-0">You have no new notifications</p>
          </div>
        </div>
      </div>
      <!-- list item-->
      <div *ngIf="notifications == null && fetchingNotifications" class="list-group-item list-group-item-action">
        <div class="media">
          <app-spinner></app-spinner>
        </div>
      </div>
      <div *ngFor="let notification of notifications; let i = index;" class="list-group-item list-group-item-action" (click)="redirectTo(notification.navigateTo)">
        <div class="media">
          <div class="align-self-start mr-2">
            <em [class]="notificationService.getNotificationIconClass(notification.type)"></em>
          </div>
          <div class="media-body">
            <p class="m-0">{{notificationService.getNotificationTypeText(notification.type)}}</p>
            <p class="m-0 text-muted text-sm" [innerHTML]="notification.message"></p>
          </div>
        </div>
      </div>

      <!-- last list item-->
      <div *ngIf="notificationsLeft > 0" class="list-group-item list-group-item-action">
        <span class="d-flex align-items-center">
          <span class="text-sm">More notifications</span>
          <span class="badge badge-danger ml-auto">{{notificationsLeft}}</span>
        </span>
      </div>
    </div>
    <!-- END list group-->
  </div>
</div>
<!-- END Dropdown menu-->
