<div class="wrapper">
    <div class="block-center mt-4 wd-xl">
        <!-- START card-->
        <div class="card card-flat">
            <div class="card-header text-center bg-dark">
                <a href="#">
                    <img class="block-center rounded" src="assets/img/logoMyAccessHorizontalNegative.png" alt="Image" />
                </a>
            </div>
            <div class="card-body">
                <p class="text-center py-2">RESET YOUR PASSWORD</p>
                <form *ngIf="isTokenUsable && !isPasswordChangeRequestSent" [formGroup]="valForm" class="form-validate mb-3" role="form" name="registerForm" novalidate="" (submit)="submitForm($event, valForm.value)">
                    <div formGroupName="passwordGroup">
                        <div class="form-group">
                            <label class="text-muted mb-1">Password</label>
                            <div class="input-group with-focus">
                                <input class="form-control border-right-0" id="id-password" type="password" name="password" formControlName="password" [formControl]="$any(valForm).get('passwordGroup.password')" />
                                <div class="input-group-append">
                                    <span class="input-group-text fa fa-lock text-muted bg-transparent border-left-0"></span>
                                </div>
                                <div class="input-group">
                                  <span class="text-danger" *ngIf="!validatePasswordResult.passwordValidated">
                                      {{validatePasswordResult.passwordValidationErrors}}
                                  </span>
                                </div>
                            </div>
                            <div class="text-danger" *ngIf="valForm.get('passwordGroup.password')?.hasError('required') && (valForm.get('passwordGroup.password')?.dirty || valForm.get('passwordGroup.password')?.touched)">This field is required</div>
                            <div class="text-danger" *ngIf="valForm.get('passwordGroup.password')?.hasError('pattern') && (valForm.get('passwordGroup.password')?.dirty || valForm.get('passwordGroup.password')?.touched)">Input should match 'a-zA-Z0-9' and 6-10 length</div>
                        </div>
                        <div class="form-group">
                            <label class="text-muted mb-1">Retype Password</label>
                            <div class="input-group with-focus">
                                <input class="form-control border-right-0" type="password" name="confirmPassword" formControlName="confirmPassword" [formControl]="$any(valForm).get('passwordGroup.confirmPassword')" />
                                <div class="input-group-append">
                                    <span class="input-group-text fa fa-lock text-muted bg-transparent border-left-0"></span>
                                </div>
                            </div>
                            <div class="text-danger" *ngIf="$any(valForm).get('passwordGroup.confirmPassword').hasError('required') && (valForm.get('passwordGroup.confirmPassword')?.dirty || valForm.get('passwordGroup.confirmPassword')?.touched)">This field is required</div>
                            <div class="text-danger" *ngIf="$any(valForm).get('passwordGroup.confirmPassword').hasError('equalTo')">Password does Not match</div>
                        </div>
                    </div>
                    <button class="btn btn-block btn-primary mt-3" type="submit">Reset Password</button>
                </form>
                <div *ngIf="!isTokenUsable">
                    <p class="text-center py-2">Your recovery token seems to be expired. Please request again using the button below.</p>
                    <a class="btn btn-block btn-secondary" [routerLink]="'/recover'">Request Password Change</a>
                </div>
                <div *ngIf="isPasswordChangeRequestSent && isPasswordChanged">
                    <p class="text-center py-2">Your password has been successfully changed.</p>
                    <a class="btn btn-block btn-secondary" [routerLink]="'/login'">Log In</a>
                </div>
            </div>
        </div>
        <!-- END card-->
        <div class="p-3 text-center">
            <span>&copy;</span>
            <span>{{ settings.app.year }}</span>
            <span class="mx-2">-</span>
            <span>{{ settings.app.name }}</span>
            <br />
            <span>{{ settings.app.description }}</span>
        </div>
    </div>
</div>
