import {Component, OnInit} from '@angular/core';
import { SettingsService } from "../../../services/shared/settings.service";
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import { RecoverPassword } from '@app/models/password-recovery/recover-password';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordRecoveryService } from '@app/services/password-recovery/password-recovery.service';
import { CheckRecoveryToken } from '@app/models/password-recovery/check-recovery-token.model';
import {CustomValidators} from "@narik/custom-validators";
import {ClientPasswordPolicy} from "@app/models/client/client.password.policy.model";
import {UserAdminService} from "@app/services/useradmin/user-admin.service";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {CustomToastrService} from "@app/services/shared/custom-toastr.service";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public isTokenUsable: boolean = false;
  public isPasswordChanged: boolean = false;
  public isPasswordChangeRequestSent: boolean = false;
  public valForm: FormGroup;
  public passwordForm: FormGroup;
  public clientPasswordPolicy: ClientPasswordPolicy = new ClientPasswordPolicy();
  public validatePasswordResult: ClientPasswordPolicy = new ClientPasswordPolicy();

  constructor(
    public settings: SettingsService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private passwordRecoveryService: PasswordRecoveryService,
    private userAdminService: UserAdminService,
    private toasterService: CustomToastrService) {
    let password = new FormControl(null, Validators.required);
    let certainPassword = new FormControl('', CustomValidators.equalTo(password));

    this.passwordForm = fb.group({
      'password': password,
      'confirmPassword': certainPassword
    });

    this.valForm = fb.group({
      'passwordGroup': this.passwordForm
    });
  }

  public submitForm($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    for (let c in this.passwordForm.controls) {
      this.passwordForm.controls[c].markAsTouched();
    }

    const password = this.passwordForm.controls["password"].value;
    if (password != null && password.length > 0) {
      if (!this.validatePasswordResult.passwordValidated) {
        this.toasterService.error("Password has password policy validation error");
        return false;
      }
    }

    if (this.isTokenUsable && this.valForm.valid) {
      this.isPasswordChangeRequestSent = true;
      const model: RecoverPassword = Object.assign({ token: this.activatedRoute.snapshot.queryParamMap.get('token') }, this.passwordForm.value);

      this.passwordRecoveryService.recoverPassword(model).subscribe(
        () => {
          this.isPasswordChanged = true;
        },
        () => {
          this.isPasswordChanged = false;
          this.isPasswordChangeRequestSent = false;
        }
      );
    }
  }

  public ngOnInit() {
    const model: CheckRecoveryToken = { token: this.activatedRoute.snapshot.queryParamMap.get('token') ?? '' };
    if (model.token == null) {
      this.router.navigate(['/']);
    }

    this.passwordForm.get("password")?.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(password => {
        if (password && password.length > 0) {
          let policy = new ClientPasswordPolicy();
          policy.clientId = this.clientPasswordPolicy.clientId;
          policy.password = password;
          this.userAdminService.validatePassword(policy).subscribe(result => {
            this.validatePasswordResult = result;
          }, error => {
            this.toasterService.error(error.error, 'Password validation');
          });
        }
    });

    this.passwordRecoveryService.checkRecoveryToken(model).subscribe(
      (result) => {
        if(result) {
          this.isTokenUsable = true;
          this.clientPasswordPolicy = result;
        }else {
          this.isTokenUsable = false;
        }
      }, error => {
        this.isTokenUsable = false;
        this.toasterService.error(error.error, 'Recovery token check');
      }
    );
  }

}
