import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MenuService } from '@app/services/shared/menu.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss']
})
export class ContentHeaderComponent implements OnInit {

  pageTitle: string | null = null;
  pageSubtitle: string | null = null;
  showTitleBar: boolean = true;

  constructor(router: Router, menuService: MenuService) {
    router.events
      .pipe(
        filter(
          (event) => {
            return (event instanceof NavigationEnd);
          }
        )
      )
      .subscribe(
        (event: NavigationEnd) => {
          const menuItem = menuService.getMenuDetailsByRoute(event.urlAfterRedirects);
          if (menuItem !== null) {
            this.showTitleBar = true;
            this.pageTitle = menuItem.pageTitle ?? menuItem.displayText;
            this.pageSubtitle = menuItem.pageSubTitle ?? "";
          }
          else {
            this.showTitleBar = false;
          }
        }
      );
  }

  ngOnInit() {
  }

}
