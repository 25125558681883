<div class="wrapper">
  <div class="abs-center wd-xl">
    <!-- START card-->
    <div class="d-flex justify-content-center">
      <div class="p-2">
        <img class="img-thumbnail rounded-circle" [src]="userLockService.getProfilePictureUrl()" alt="Avatar" width="60" height="60" />
      </div>
    </div>
    <div class="card b0">
      <div class="card-body">
        <p class="text-center">Please login to unlock your screen.</p>
        <form [formGroup]="form" role="form" name="lockForm" (submit)="submitForm($event)">
          <div class="form-group">
            <div class="input-group with-focus">
              <input class="form-control border-right-0" id="exampleInputPassword1" autocomplete="off" type="password" placeholder="Password" name="password" formControlName="password" />
              <div class="input-group-append">
                <span class="input-group-text fa fa-lock text-muted bg-transparent border-left-0"></span>
              </div>
            </div>
            <div class="text-danger" *ngIf="form.controls['password'].hasError('required') && (form.controls['password'].dirty || form.controls['password'].touched)">This field is required</div>
          </div>
          <div class="d-flex">
            <div class="mt-1">
              <a class="text-muted" [routerLink]="'/recover'">
                <span class="text-sm">Forgot your password?</span>
              </a>
            </div>
            <div class="ml-auto">
              <!-- Change this button type to submit to send the form data-->
              <button class="btn btn-sm btn-primary" type="submit">Unlock</button>
            </div>
            <div class="ml-auto">
              <!-- Change this button type to submit to send the form data-->
              <button class="btn btn-sm btn-secondary" type="button" (click)="logout()">Logout</button>
            </div>

          </div>
        </form>
      </div>
    </div>
    <!-- END card-->
    <div class="p-3 text-center">
      <span>&copy;</span>
      <span>{{ settings.app.year }}</span>
      <span class="mx-2">-</span>
      <span>{{ settings.app.name }}</span>
      <br />
      <span>{{ settings.app.description }}</span>
    </div>
  </div>
</div>
