import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Notification as NotificationModel } from '@app/models/notification/notification.model';
import { NotificationType, NotificationTypes } from '@app/models/notification/notification.types';
import { Subject } from 'rxjs';
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { TokenService } from '@app/services/shared/token.service';
import { Environment } from '@env/environment.global';

@Injectable({ providedIn: 'root' })
export class NotificationService {

  notificationReceived = new Subject<NotificationModel>();
  private service = 'NotificationService';
  private hubConnection: HubConnection;

  constructor(private http: HttpClient, private tokenService: TokenService, private environment: Environment) {
    this.initializeNotificationConnection();
  }

  public getUnseenNotifications() {
    return this.http.get<NotificationModel[]>(`api/${this.service}/getUnseenNotifications`);
  }

  public getUnseenNotificationsCount() {
    return this.http.get<number>(`api/${this.service}/getUnseenNotificationsCount`);
  }

  public markNotificationsAsSeen() {
    return this.http.put(`api/${this.service}/markNotificationsAsSeen`, null);
  }

  private connectNotificationHub() {
    this.hubConnection.start()
      .then(result => {
        console.log("Socket connection established.");
      })
      .catch(error => {
        setTimeout(() => {
          this.connectNotificationHub();
        }, 5000);
        console.log("Socket connection lost!. Reconnecting...");
      });
  }

  private initializeNotificationConnection() {
    if (this.hubConnection && this.hubConnection.state === HubConnectionState.Connected) {
      return;
    }

    this.hubConnection = new HubConnectionBuilder()
      .configureLogging(LogLevel.Warning)
      .withUrl(this.environment.getBaseUrl() + 'notification', { accessTokenFactory: () => new Promise<string>((resolve, reject) => resolve(this.tokenService.get('token') ?? '')) })
      .withAutomaticReconnect()
      .build();

    this.hubConnection.on('SendNotificationAsync', (notificationType, message, navigateTo) => {
      const notification: NotificationModel = { type: notificationType, message: message, navigateTo: navigateTo };
      this.notificationReceived.next(notification);
    });

    this.hubConnection.onclose(error => {
      this.connectNotificationHub();
      console.log("Socket connection closed!");
    });

    this.connectNotificationHub();
  }

  public getNotificationIconClass(type: NotificationType): string {
    switch (type) {
      case NotificationType.Ticket:
        return 'fa fa-bug fa-2x text-info';

      case NotificationType.Announcement:
        return 'fa fa-bullhorn fa-2x text-warning';

      case NotificationType.Upload:
        return 'fa fa-cloud-upload fa-2x text-warning';

      case NotificationType.Administration:
        return 'fa fa-bullhorn fa-2x text-danger';

      case NotificationType.Subscription:
        return 'fa fa-credit-card fa-2x text-danger';

      default:
        return 'fa fa-tasks fa-2x text-success';
    }
  }

  public getNotificationTypeText(type: NotificationType) {
    if (!type) {
      return null;
    }
    return NotificationTypes.find(i => i.id === type).text;
  }
  public closeConnection() {
    this.hubConnection.stop();
  }
}
