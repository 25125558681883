import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from "@app/services/login/authentication.service";
import { UserService } from "@app/services/shared/user.service";

@Component({
  selector: 'app-userblock',
  templateUrl: './userblock.component.html',
  styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {

  public user: any;
  public profilePicture: any;

  constructor(private userService: UserService,
    private authenticationService: AuthenticationService) {

  }

  public ngOnInit() {
    this.userService.get.subscribe((result) => {
      this.user = result;
      this.profilePicture =this.user.profilePictureUrl;
    },);
  }

  public userBlockIsVisible() {
    return this.user != null && this.userService.getVisibility();
  }

  public logout() { this.authenticationService.logout(); }

}
