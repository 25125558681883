import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { RecoverComponent } from './password-recovery/recover.component';
import { LockComponent } from './lock/lock.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { Error404Component } from './error404/error404.component';
import { Error500Component } from './error500/error500.component';
import { LoginComponent } from "../login/login.component";
import { ResetPasswordComponent } from './password-recovery/reset-password.component';
import { Error401Component } from './error401/error401.component';
import { SsoLogoutComponent } from './sso-logout/sso-logout.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    LoginComponent,
    RecoverComponent,
    ResetPasswordComponent,
    LockComponent,
    MaintenanceComponent,
    Error401Component,
    Error404Component,
    Error500Component,
    SsoLogoutComponent
  ],
  exports: [
    RouterModule,
    LoginComponent,
    RecoverComponent,
    ResetPasswordComponent,
    LockComponent,
    MaintenanceComponent,
    Error401Component,
    Error404Component,
    Error500Component
  ]
})
export class PagesModule {
}
