import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Configuration } from '@azure/msal-browser';
import { msalConfigDev, b2cPoliciesDev, msalConfigProd, b2cPoliciesProd } from '@app/shared/configs/auth-config';

@Injectable({ providedIn: "root" })
export class Environment {


    public defaultImagePath: string = "assets/img/misc/noimage.jpg";
    public urlMap = new Map([
        ["http://localhost:4200", "http://localhost:5000/"],

        ["https://appservice-myaccess-admin-client000.azurewebsites.net", "https://appservice-myaccess-backend-client000.azurewebsites.net/"],
        ["https://client000-myaccess.qcic-group.com", "https://appservice-myaccess-backend-client000.azurewebsites.net/"],
        ["https://appservice-my-access-admin-client000.azurewebsites.net", "https://appservice-my-access-backend-client000.azurewebsites.net/"],
        ["https://client000.my-access.com", "https://appservice-my-access-backend-client000.azurewebsites.net/"],

        ["https://appservice-myaccess-admin-client001.azurewebsites.net", "https://appservice-myaccess-backend-client001.azurewebsites.net/"],
        ["https://client001-myaccess.qcic-group.com", "https://appservice-myaccess-backend-client001.azurewebsites.net/"],
        ["https://appservice-my-access-admin-client001.azurewebsites.net", "https://appservice-my-access-backend-client001.azurewebsites.net/"],
        ["https://client001.my-access.com", "https://appservice-my-access-backend-client001.azurewebsites.net/"],

        ["https://appservice-myaccess-admin-client002.azurewebsites.net", "https://appservice-myaccess-backend-client002.azurewebsites.net/"],
        ["https://appservice-my-access-admin-client002.azurewebsites.net", "https://appservice-my-access-backend-client002.azurewebsites.net/"],
        ["https://client002.my-access.com", "https://appservice-my-access-backend-client002.azurewebsites.net/"],

        ["https://appservice-myaccess-admin-dev.azurewebsites.net", "https://appservice-myaccess-backend-dev.azurewebsites.net/"],
        ["https://dev001-myaccess.qcic-group.com", "https://appservice-myaccess-backend-dev.azurewebsites.net/"],
        ["https://appservice-my-access-admin-dev.azurewebsites.net", "https://appservice-my-access-backend-dev.azurewebsites.net/"],
        ["https://dev.my-access.com", "https://appservice-my-access-backend-dev.azurewebsites.net/"],

        ["https://appservice-myaccess-admin-test.azurewebsites.net", "https://appservice-myaccess-backend-test.azurewebsites.net/"],
        ["https://appservice-my-access-admin-test.azurewebsites.net", "https://appservice-my-access-backend-test.azurewebsites.net/"],
        ["https://test.my-access.com", "https://appservice-my-access-backend-test.azurewebsites.net/"],
    ]);

    public static ssoConfigMap = new Map([
        ["http://localhost:4200", msalConfigDev],
        ["https://dev.my-access.com", msalConfigDev],
        ["https://test.my-access.com", msalConfigDev],
        ["https://client000.my-access.com", msalConfigProd],
        ["https://client001.my-access.com", msalConfigProd],
        ["https://client002.my-access.com", msalConfigProd],
    ]);

    public static ssoPolicyMap = new Map([
        ["http://localhost:4200", b2cPoliciesDev],
        ["https://dev.my-access.com", b2cPoliciesDev],
        ["https://test.my-access.com", b2cPoliciesDev],
        ["https://client000.my-access.com", b2cPoliciesProd],
        ["https://client001.my-access.com", b2cPoliciesProd],
        ["https://client002.my-access.com", b2cPoliciesProd],
    ]);

    constructor(private router: Router) {

    }
    public getBaseUrl(): string {
        var backendServiceUrl = this.urlMap.get(window.location.origin);
        var baseUrl = backendServiceUrl != null ? backendServiceUrl : environment.rawBaseUrl;
        return baseUrl;
    }
    public isDevelopment(): boolean {
        return window.location.origin.includes("localhost");
    }
    public static getSsoMsalConfig(): Configuration {
        var msalConfiguration = Environment.ssoConfigMap.get(window.location.origin);
        return msalConfiguration != null ? msalConfiguration : msalConfigDev;
    }

    public static getSsoPolicy() {
        var ssoPolicy = Environment.ssoPolicyMap.get(window.location.origin);
        return ssoPolicy != null ? ssoPolicy : b2cPoliciesDev;
    }

}
