<div class="wrapper">
    <div class="abs-center wd-xl">
        <!-- START card-->
        <div class="text-center mb-4">
            <div class="text-lg mb-3">401</div>
            <p class="lead m0">Access Denied!</p>
            <p>You do not have access to this page.</p>
        </div>
        <ul class="list-inline text-center text-sm mb-4">
            <li class="list-inline-item">
                <a class="text-muted" [routerLink]="'/'">Go to App</a>
            </li>
            <li class="text-muted list-inline-item">|</li>
            <li class="list-inline-item">
                <a class="text-muted" [routerLink]="'/login'">Login</a>
            </li>
        </ul>
        <div class="p-3 text-center">
            <span>&copy;</span>
            <span>{{ settings.app.year }}</span>
            <span class="mx-2">-</span>
            <span>{{ settings.app.name }}</span>
            <br/>
            <span>{{ settings.app.description }}</span>
        </div>
    </div>
</div>
