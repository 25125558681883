import { ErrorHandler, Injectable } from "@angular/core";
import { ApplicationError } from "./applicationerror";
import swal from "sweetalert";

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor() {
  }

  public handleError(error: any) {
    if(error == null) {
      return;
    }

    if(error instanceof ApplicationError) {
      swal({
        title: 'Application Error',
        text: error.message ? error.message : error.toString(),
        icon: 'warning',
        buttons: {
          cancel: false,
          confirm: {
            text: 'Ok',
            value: true,
            visible: true,
            className: 'bg-danger',
            closeModal: true
          }
        }
      });
    } else {
      console.error(error);
    }
  }
}
