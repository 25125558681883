import { Component, OnInit } from '@angular/core';
import { SettingsService } from "../../../services/shared/settings.service";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserLockService } from '@app/services/login/user-lock.service';
import { AuthenticationService } from '@app/services/login/authentication.service';

@Component({
  selector: 'app-lock',
  templateUrl: './lock.component.html',
  styleUrls: ['./lock.component.scss']
})
export class LockComponent implements OnInit {

  public form: FormGroup;

  constructor(
    fb: FormBuilder,
    public settings: SettingsService,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    public userLockService: UserLockService) {
    this.form = fb.group({
      'password': [null, Validators.required]
    });
  }

  public logout() {
    this.userLockService.clear();
    this.authenticationService.logout();
  }

  public submitForm($event) {
    $event.preventDefault();

    for (let c in this.form.controls) {
      this.form.controls[c].markAsTouched();
    }

    if (this.form.valid) {
      this.userLockService.unlock(this.form.get("password")?.value);
    }
  }

  public ngOnInit() {
    this.userLockService.lock(this.activatedRoute.snapshot.paramMap.get("returnUrl") ?? '');
  }

}
