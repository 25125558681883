import { MenuItem } from "./menu-item.model";

export class Menu {

    public menuGroups: MenuItem[];

    constructor(){
        this.menuGroups = [];
    }

}