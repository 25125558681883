<!-- START Sidebar (left)-->
<div class="aside-inner">
  <nav class="sidebar" sidebar-anyclick-close="" [class.show-scrollbar]="settings.layout.asideScrollbar">

    <!-- START sidebar nav-->
    <ul class="sidebar-nav">

      <!-- START user info-->
      <li class="has-user-block">
        <app-userblock></app-userblock>
      </li>
      <!-- END user info-->

      <ng-template ngFor let-i="index" let-c="count" let-menu [ngForOf]="menu.menuGroups">
        <li [ngClass]="'nav-heading'" [routerLinkActive]="['active']">
          <span *ngIf="isGroup(menu)">{{menu.displayText}}</span>
        </li>
        <li *ngFor="let gItem of menu.childMenuItems" [routerLinkActive]="['active']">
          <!-- single menu item -->
          <a href *ngIf="isItem(gItem)" [routerLink]="getLink(gItem)" [attr.route]="getLink(gItem)"
             title="{{gItem.displayText}}" (click)="toggleSubmenuClick($event)"
             (mouseenter)="toggleSubmenuHover($event)">
            <span class="float-right" *ngIf="gItem.alert"
                  [ngClass]="gItem.label || 'badge badge-success'">{{gItem.alert}}</span>
            <span class="{{gItem.icon}}" *ngIf="gItem.icon"></span>
            <img *ngIf="gItem.iconUrl" src="{{gItem.iconUrl}}" width="14px" />
            <span style="margin-left: 10px;white-space: initial !important; word-wrap: break-word !important">{{gItem.displayText}}</span>
          </a>
          <!-- has submenu -->
          <a href *ngIf="isGroup(gItem)" title="{{gItem.displayText}}" (click)="toggleSubmenuClick($event)"
             (mouseenter)="toggleSubmenuHover($event)">
            <span class="float-right" *ngIf="gItem.alert"
                  [ngClass]="gItem.label || 'badge badge-success'">{{gItem.alert}}</span>
            <span class="{{gItem.icon}}" *ngIf="gItem.icon"></span>
            <img *ngIf="gItem.iconUrl" src="{{gItem.iconUrl}}" width="14px" />
            <span style="margin-left: 10px;white-space: initial !important; word-wrap: break-word !important">{{gItem.displayText}}</span>
          </a>

          <!-- SUBLEVEL -->
          <ul *ngIf="hasChildMenuItem(gItem)" class="nav sidebar-subnav" [routerLinkActive]="['opening']">
            <li class="sidebar-subnav-header">{{gItem.displayText}}</li>
            <li *ngFor='let subitem of gItem.childMenuItems' [routerLinkActive]="['active']" class="menu-item-link">
              <!-- sublevel: single menu item  -->
              <a href [routerLink]="getLink(subitem)" [attr.route]="getLink(subitem)" title="{{subitem.text}}" class="menu-item-link">
                <span class="float-right" *ngIf="subitem.alert"
                      [ngClass]="subitem.label || 'label label-success'">{{subitem.alert}}</span>
                <span class="{{subitem.icon}}" *ngIf="subitem.icon"></span>
                <img *ngIf="subitem.iconUrl" src="{{subitem.iconUrl}}" width="14px" />
                <span style="margin-left: 10px;white-space: initial !important; word-wrap: break-word !important">{{subitem.displayText}}</span>
              </a>
            <li>
          </ul>
        </li>
      </ng-template>
    </ul>
    <!-- END sidebar nav-->

  </nav>
</div>
<!-- END Sidebar (left)-->
