import { Injectable } from "@angular/core";
declare var $: any;

@Injectable()
export class SettingsService {

    public user: any;
    public app: any;
    public layout: any;

    constructor() {

        // App Settings
        // -----------------------------------
        this.app = {
            name: 'My Access',
            description: 'My Access - Admin Portal',
            year: ((new Date()).getFullYear())
        };

        // Layout Settings
        // -----------------------------------
        this.layout = {
            isFixed: true,
            isCollapsed: false,
            isBoxed: false,
            isRTL: false,
            horizontal: false,
            isFloat: false,
            asideHover: false,
            theme: null,
            asideScrollbar: false,
            isCollapsedText: false,
            useFullLayout: false,
            hiddenFooter: false,
            offsidebarOpen: false,
            asideToggled: false,
            viewAnimation: 'ng-fadeInUp'
        };

    }

    public getAppSetting(name) {
        return name ? this.app[name] : this.app;
    }

    public getUserSetting(name) {
        return name ? this.user[name] : this.user;
    }

    public getLayoutSetting(name) {
        return name ? this.layout[name] : this.layout;
    }

    public setAppSetting(name, value) {
        if (typeof this.app[name] !== 'undefined') {
            this.app[name] = value;
        }
    }

    public setUserSetting(name, value) {
        if (typeof this.user[name] !== 'undefined') {
            this.user[name] = value;
        }
    }

    public setLayoutSetting(name, value) {
        if (typeof this.layout[name] !== 'undefined') {
            return this.layout[name] = value;
        }
    }

    public toggleLayoutSetting(name) {
        return this.setLayoutSetting(name, !this.getLayoutSetting(name));
    }

    public collapseSidebarMenu() {
        this.setLayoutSetting('isCollapsed', true);
    }

    public expandSidebarMenu() {
        this.setLayoutSetting('isCollapsed', false);
    }
}
