import { Component, OnInit } from '@angular/core';
import { SettingsService } from "../../../services/shared/settings.service";

@Component({
    selector: 'app-error401',
    templateUrl: './error401.component.html',
    styleUrls: ['./error401.component.scss']
})
export class Error401Component implements OnInit {

    constructor(public settings: SettingsService) {}

    public ngOnInit() {
    }

}
