import {Input, Component, ElementRef } from '@angular/core';

@Component({
    selector: 'app-loading',
    template: `
    <div id="blocker" class="blocker" *ngIf="showIf">
        <div *ngIf="showSpinner" class="spinner-border" [ngStyle]="{'width': size, 'height': size}" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <ng-content class="content"></ng-content>
  `,
    styles: [`
    :host {
      position: relative;
      display: inline-block;
    }
    .blocker {
      display:flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 999;
      background-color: #fff;
      opacity: 0.6;
    }
  `],
})
export class AppLoadingComponent {
    @Input('showIf') showIf: boolean;
    @Input('size') size: string = '1.5em';
    @Input('showSpinner') showSpinner: boolean = true;

    constructor(
        private el: ElementRef
    ) { }

    ngAfterViewInit() {
        let blocker: any = this.el.nativeElement.querySelector('.blocker');

        let firstChild: any = Array.from(this.el.nativeElement.children)
            .find(el => el !== blocker);

        if (firstChild) {
            let firstChildStyle = window.getComputedStyle(firstChild);
            if (firstChildStyle.display === 'block') {
                this.el.nativeElement.style.display = 'block';
            }
            if (firstChildStyle.borderRadius && blocker) {
                blocker.style.borderRadius = firstChildStyle.borderRadius;
            }
        }
    }
}
