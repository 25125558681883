<div class="wrapper">
  <div class="block-center mt-4 wd-xxl">
    <!-- START card-->
    <div class="card card-flat">
      <div class="card-header text-center bg-dark">
        <a href="#">
          <img class="block-center rounded" src="assets/img/logoMyAccessHorizontalNegative.png" alt="Image" />
        </a>
      </div>
      <div class="card-body">
        <p class="text-center py-2">PASSWORD RESET</p>
        <form *ngIf="!recoveryMailSent" [formGroup]="form" class="form-validate" role="form" name="recoverForm" novalidate="" (submit)="submitForm($event)">
          <p class="text-center">Fill with your mail to receive instructions on how to reset your password.</p>
          <div class="form-group">
            <label class="text-muted">Email address</label>
            <div class="input-group with-focus">
              <input class="form-control border-right-0" type="email" name="email" placeholder="Enter email" autocomplete="off" formControlName="email" />
              <div class="input-group-append">
                <span class="input-group-text fa fa-envelope text-muted bg-transparent border-left-0"></span>
              </div>
            </div>
            <div class="text-danger" *ngIf="form.controls['email'].hasError('required') && (form.controls['email'].dirty || form.controls['email'].touched)">This field is required</div>
            <div class="text-danger" *ngIf="form.controls['email'].hasError('email') && (form.controls['email'].dirty || form.controls['email'].touched)">This field must be a valid email address</div>
          </div>
          <div class="form-group">
            <div align="center">
              <ngx-recaptcha2 #captcha [siteKey]="siteKey" formControlName="captchaToken"></ngx-recaptcha2>
            </div>
            <div class="text-danger" *ngIf="form.controls['captchaToken'].hasError('required') && (form.controls['captchaToken'].dirty || form.controls['captchaToken'].touched)">This field is required</div>
          </div>
          <button class="btn btn-danger btn-block" type="submit">Reset</button>
        </form>
        <p *ngIf="recoveryMailSent" class="text-center">You will receive a recovery link to change your password soon. Please check your inbox.</p>
      </div>
    </div>
    <!-- END card-->
    <div class="p-3 text-center">
      <span>&copy;</span>
      <span>{{ settings.app.year }}</span>
      <span class="mx-2">-</span>
      <span>{{ settings.app.name }}</span>
      <br />
      <span>{{ settings.app.description }}</span>
    </div>
  </div>
</div>
