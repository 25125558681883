import { Component, OnInit, Injector, OnDestroy, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

import { SettingsService } from "@app/services/shared/settings.service";
import { MenuService } from "@app/services/shared/menu.service";

import { Menu } from '@app/models/authorization-management/menu-admin/menu.model';
import { MenuItem } from '@app/models/authorization-management/menu-admin/menu-item.model';
import { MenuItemType } from '@app/models/authorization-management/menu-admin/menuitem.type.enum';
import { AuthenticatedModel } from '@app/models/authentication/authenticated.model';
import { UserService } from '@app/services/shared/user.service';
import { Role } from "@app/models/shared/role.enum";
import { AuthorizationManagementService } from '@app/services/authorization-management/authorization-management.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit, OnDestroy {
    public menu: Menu = new Menu();
    public loggedInUser: AuthenticatedModel;

    public router: Router;
    public sbclickEvent = 'click.sidebar-toggle';
    public $doc: any = null;

    constructor(private authorizationService: AuthorizationManagementService, private menuService: MenuService, public settings: SettingsService,
        private userService: UserService,
        public injector: Injector,
        private cd: ChangeDetectorRef) {
        this.userService.get.subscribe((result) => {
            this.loggedInUser = result;
        },);
        this.initUserMenu();
    }

    public ngOnInit() {
        this.loggedInUser = this.userService.getLoggedInUser();

        this.router = this.injector.get(Router);

        this.router.events.subscribe((val) => {
            // close any submenu opened when route changes
            this.removeFloatingNav();
            // scroll view to top
            window.scrollTo(0, 0);
            // close sidebar on route change
            this.settings.layout.asideToggled = false;
        });

        // enable sidebar autoclose from extenal clicks
        this.anyClickClose();
        this.cd.detectChanges();
    }
    public initUserMenu() {
        this.authorizationService.getUserMenu().subscribe(result => {
            this.menu = result;
            this.menuService.setMenu(this.menu);
        }, error => {
            console.log(error);
        });
    }

    public isGroup(item: MenuItem) {
        return item.menuItemType == MenuItemType.Group;
    }

    public isItem(item: MenuItem) {
        return item.menuItemType == MenuItemType.Item;
    }

    public getLink(item: MenuItem) {
        return item.pageItem?.url;

    }

    public hasChildMenuItem(item: MenuItem) {
        if (item.childMenuItems && item.childMenuItems.length > 0)
            return true;
        return false;
    }

    public anyClickClose() {
        this.$doc = $(document).on(this.sbclickEvent,
            (e) => {
                if (!$(e.target).parents('.aside-container').length) {
                    this.settings.layout.asideToggled = false;
                }
            });
    }

    public ngOnDestroy() {
        if (this.$doc)
            this.$doc.off(this.sbclickEvent);
    }

    public toggleSubmenuClick(event) {
        if (!this.isSidebarCollapsed() && !this.isSidebarCollapsedText() && !this.isEnabledHover()) {
            event.preventDefault();

            let target = $(event.target || event.srcElement || event.currentTarget);
            let ul, anchor = target;

            // find the UL
            if (!target.is('a')) {
                anchor = target.parent('a').first();
            }
            ul = anchor.next();

            // hide other submenus
            let parentNav = ul.parents('.sidebar-subnav');
            $('.sidebar-subnav').each((idx, el) => {
                let $el = $(el);
                // if element is not a parent or self ul
                if (!$el.is(parentNav) && !$el.is(ul)) {
                    this.closeMenu($el);
                }
            });

            // abort if not UL to process
            if (!ul.length) {
                return;
            }

            // any child menu should start closed
            ul.find('.sidebar-subnav').each((idx, el) => {
                this.closeMenu($(el));
            });

            // toggle UL height
            if (parseInt(ul.height(), 0)) {
                this.closeMenu(ul);
            } else {
                // expand menu
                ul.on('transitionend',
                    () => {
                        ul.height('auto').off('transitionend');
                    }).height(ul[0].scrollHeight);
                // add class to manage animation
                ul.addClass('opening');
            }

        }

    }

    // Close menu collapsing height
    public closeMenu(elem) {
        elem.height(elem[0].scrollHeight); // set height
        elem.height(0); // and move to zero to collapse
        elem.removeClass('opening');
    }

    public toggleSubmenuHover(event) {
        let self = this;
        if (this.isSidebarCollapsed() || this.isSidebarCollapsedText() || this.isEnabledHover()) {
            event.preventDefault();

            this.removeFloatingNav();

            let target = $(event.target || event.srcElement || event.currentTarget);
            let ul, anchor = target;
            // find the UL
            if (!target.is('a')) {
                anchor = target.parent('a');
            }
            ul = anchor.next();

            if (!ul.length) {
                return; // if not submenu return
            }

            let $aside = $('.aside-container');
            let $asideInner = $aside.children('.aside-inner'); // for top offset calculation
            let $sidebar = $asideInner.children('.sidebar');
            let mar = parseInt($asideInner.css('padding-top'), 0) + parseInt($aside.css('padding-top'), 0);
            let itemTop = ((anchor.parent().position().top) + mar) - $sidebar.scrollTop();

            let floatingNav = ul.clone().appendTo($aside);
            let vwHeight = $(window).height();

            floatingNav
                .removeClass('opening') // necesary for demo if switched between normal//collapsed mode
                .addClass('nav-floating')
                .css({
                    position: this.settings.layout.isFixed ? 'fixed' : 'absolute',
                    top: itemTop,
                    bottom: (floatingNav.outerHeight(true) + itemTop > vwHeight) ? 0 : 'auto'
                });

            floatingNav
                .on('mouseleave', () => { floatingNav.remove(); })
                .find('a').on('click',
                    function (e) {
                        e.preventDefault(); // prevents page reload on click
                        // get the exact route path to navigate
                        let routeTo = $(this).attr('route');
                        if (routeTo) self.router.navigate([routeTo]);
                    });

            this.listenForExternalClicks();

        }

    }

    public listenForExternalClicks() {
        let $doc = $(document).on('click.sidebar',
            (e) => {
                if (!$(e.target).parents('.aside-container').length) {
                    this.removeFloatingNav();
                    $doc.off('click.sidebar');
                }
            });
    }

    public removeFloatingNav() {
        $('.nav-floating').remove();
    }

    public isSidebarCollapsed() {
        return this.settings.layout.isCollapsed;
    }

    public isSidebarCollapsedText() {
        return this.settings.layout.isCollapsedText;
    }

    public isEnabledHover() {
        return this.settings.layout.asideHover;
    }
}
