<div class="item user-block" *ngIf="userBlockIsVisible()">
    <!-- User picture-->
    <div class="user-block-picture">
        <div class="user-block-status">
            <img class="img-thumbnail rounded-circle" [src]="profilePicture" onerror='this.src="assets/img/misc/noimage.jpg"'  alt="Avatar"/>
            <div class="circle bg-success circle-lg"></div>
        </div>
    </div>
    <!-- Name and Job-->
    <div class="user-block-info">
        <span class="user-block-name">WELCOME {{user.name}}</span>
        <span class="user-block-role">Programmer</span>
        <span class="user-block-role">
            <a (click)="logout()">Logout</a>
        </span>
    </div>
</div>
