export class UserSampleInfo {
  public id: number;
  public userId: number;
  public externalUserId: string;
  public email: string;
  public name: string;
  public surname: string;
  public honorificPrefix: string;
  public honorificSuffix: string;
  public providerUserId: string;
  public providerUserReferenceId: number;
}
