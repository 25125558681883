import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { routes } from './routes';
import { TokenService } from "@app/services/shared/token.service";

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    PagesModule
  ],
  declarations: [],
  exports: [
    RouterModule
  ]
})
export class RoutesModule {
  constructor(tokenService: TokenService) {
    
  }
}
