import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { User } from "@app/models/administration/user.model";
import { UserListModel } from '@app/models/user/userlist.model';
import { Environment } from '@env/environment.global';
import { UserSampleInfo } from '@app/models/administration/usersampleinfo.model';
import { UserInvitation } from '@app/models/administration/userinvitation.model';
import { UserDevice } from '@app/models/administration/userdevice.model';
import { UserDeviceCredential } from '@app/models/administration/user-device-credential.model';
import { UserDevicePart } from '@app/models/administration/userdevicepart.model';
import { UserImport } from '@app/models/user/user-import.model';
import { ClientPasswordPolicy } from '@app/models/client/client.password.policy.model';
import { GenericResult } from '@app/models/shared/generic-result';
import { GeneratedInvitation } from '@app/models/administration/generated-invitation.model';
import { PagedListViewModel } from '@app/models/shared/pagedlist.model';
import { SearchUserRequestModel } from '@app/models/user/search-user-request.model';
import { ProviderCreationTypeEnum } from '@app/models/administration/provider-creation-type.enum';
import { UserCredentialsImportAcsModel } from '@app/models/user/user-credentials-import-acs.model';
import { SearchUserInvitationRequestModel } from '@app/models/administration/search-user-invitation-request.model';
import { SearchUserDeviceRequestModel } from '@app/models/administration/search-user-device-request.model';
import { SearchUserCredentialRequestModel } from '@app/models/administration/search-user-credential-request.model';
import { UserResendInvitationModel } from "@app/models/administration/user-resend-invitation.model";
import { MobileAppVersionModel } from "@app/models/administration/mobile-app-version.model";
import { AuthenticationResult } from '@app/models/authentication/authentication-result.model';
import swal from 'sweetalert';
import { Client } from '@app/models/client/client.model';
import { SearchUserPassRequestModel } from '@app/models/administration/search-user-pass-request.model';
import { UserPass } from '@app/models/pass-management/user-pass.model';
import { UserListSummaryModel } from '@app/models/administration/user-list-summary.model';

@Injectable({ providedIn: "root" })
export class UserAdminService {

  private service = "api/UserAdminService";
  public functionImageFileUploadService: string;
  public functionUploadUsersDatasheetService: string;
  public functionUploadCredentialsAccessControlSystemService: string;

  constructor(private http: HttpClient, environment: Environment) {
    this.functionImageFileUploadService = environment.getBaseUrl() + this.service + '/upload';
    this.functionUploadUsersDatasheetService = environment.getBaseUrl() + this.service + '/uploadUsers';
    this.functionUploadCredentialsAccessControlSystemService = environment.getBaseUrl() + this.service + '/uploadCredentialsAccessControlSystem';
  }

  public searchUsers(searchUserRequestModel: SearchUserRequestModel) {
    return this.http.post<PagedListViewModel<UserListModel>>(`${this.service}/search`, searchUserRequestModel, { responseType: "json" });
  }

  public getUser(id: number) {
    return this.http.get<User>(`${this.service}/getUser/${id}`, { responseType: "json" });
  }

  public updateUser(user: User) {
    return this.http.put<User>(`${this.service}/update`, user, { responseType: "json" });
  }

  public addUser(user: User) {
    return this.http.post<User>(`${this.service}/insert`, user, { responseType: "json" });
  }

  public resendInvitation(userId: number) {
    return this.http.post<number>(`${this.service}/resend-invitation/${userId}`, { responseType: "json" });
  }

  public deactivateUser(id: number) {
    return this.http.put<any>(`${this.service}/deactivate/${id}`, { responseType: "json" });
  }

  public deleteUser(id: number) {
    return this.http.delete<any>(`${this.service}/delete/${id}`, { responseType: "json" });
  }

  public activateUser(id: number) {
    return this.http.post<any>(`${this.service}/activate/${id}`, { responseType: "json" });
  }

  public createUser() {
    let user = new User();
    user.id = 0;
    user.isActive = true;
    user.providerCreationType = ProviderCreationTypeEnum.DontIssueMobileIdAndInvitation;//default
    user.sampleInfo = new UserSampleInfo();
    user.clientIsTenant = true;
    user.imeiNumbers = [];
    return user;
  }
  public getSampleInfo() {
    return this.http.get<UserSampleInfo>(`${this.service}/getSampleInfo`, { responseType: "json" });
  }

  public updateMobileUserInformationOnProvider(userToEdit: User) {
    return this.http.post<User>(`${this.service}/updateMobileUserInformationOnProvider`, userToEdit, { responseType: "json" });
  }

  public getInvitationsFromProvider(providerUserId: number) {
    return this.http.get<UserInvitation[]>(`${this.service}/getInvitationsFromProvider/${providerUserId}`, { responseType: "json" });
  }
  public sendNewInvitationCode(userToEdit: User) {
    return this.http.post<UserInvitation>(`${this.service}/sendNewInvitationCode`, userToEdit, { responseType: "json" });
  }

  public cancelInvitation(invitation: UserInvitation) {
    return this.http.post<UserInvitation>(`${this.service}/cancelInvitation`, invitation, { responseType: "json" });
  }

  public getInvitationDetail(invitation: UserInvitation) {
    return this.http.post<UserInvitation>(`${this.service}/getInvitationDetail`, invitation, { responseType: "json" });
  }

  public getDevicesFromProvider(providerUserId: number) {
    return this.http.get<UserDevice[]>(`${this.service}/getDevicesFromProvider/${providerUserId}`, { responseType: "json" });
  }

  public getAvailablePartNumbers(clientId: number) {
    return this.http.get<UserDeviceCredential[]>(`${this.service}/getClientPartNumbers/${clientId}`, { responseType: "json" });
  }

  public revokeMobileId(userdevicepart: UserDevicePart) {
    return this.http.post(`${this.service}/revokeMobileId`, userdevicepart, { responseType: "json" });
  }

  public issueMobileId(userdevicepart: UserDevicePart) {
    return this.http.post<UserDeviceCredential>(`${this.service}/issueMobileId`, userdevicepart, { responseType: "json" });
  }

  public addUsers(users: UserImport[]) {
    return this.http.post<string>(`${this.service}/addUsers`, users, { responseType: "text" as "json" });
  }

  public generatePasswordByClientPolicy(clientId: number): any {
    return this.http.get<ClientPasswordPolicy>(`${this.service}/generatePassword/${clientId}`, { responseType: "json" });
  }

  public validatePassword(policy: ClientPasswordPolicy) {
    return this.http.post<ClientPasswordPolicy>(`${this.service}/validatePassword`, policy, { responseType: "json" });
  }

  public getEncryptedInvitationCode(invitationCode: string) {
    return this.http.get<string>(`${this.service}/getEncryptedInvitation/${invitationCode}`, { responseType: "text" as "json" });
  }

  public sendMailInvitationCode(userId: number, code: string) {
    return this.http.post<GenericResult>(`${this.service}/sendInvitationMail/${userId}/${code}`, { responseType: "json" });
  }

  public sendGeneratedInvitationCode(generatedInvitation: GeneratedInvitation) {
    return this.http.post<GeneratedInvitation>(`${this.service}/sendGeneratedInvitationCode`, generatedInvitation, { responseType: "json" });
  }

  public getUserDevices(searchUserDeviceRequestModel: SearchUserDeviceRequestModel) {
    return this.http.post<PagedListViewModel<UserDevice>>(`${this.service}/getUserDevices`, searchUserDeviceRequestModel, { responseType: "json" });
  }

  public deleteUserDevice(userDeviceId: number) {
    return this.http.post<UserDevice>(`${this.service}/deleteUserDevice/${userDeviceId}`, { responseType: "json" });
  }

  public syncUserDevicesWithProvider(userId: number) {
    return this.http.post<UserDevice[]>(`${this.service}/syncUserDevicesWithProvider/${userId}`, { responseType: "json" });
  }

  public userNameExists(userName: string) {
    return this.http.get<GenericResult>(`${this.service}/userNameExists/${userName}`, { responseType: "json" });
  }

  public emailExists(email: string) {
    return this.http.get<GenericResult>(`${this.service}/emailExists/${email}`, { responseType: "json" });
  }

  public getUserDeviceCredentials(userDeviceId: number) {
    return this.http.get<UserDeviceCredential[]>(`${this.service}/getUserDeviceCredentials/${userDeviceId}`, { responseType: "json" });
  }

  public getUserInvitationsPaged(searchUserInvitationRequestModel: SearchUserInvitationRequestModel) {
    return this.http.post<PagedListViewModel<UserInvitation>>(`${this.service}/getUserInvitations`, searchUserInvitationRequestModel, { responseType: "json" });
  }
  public getUserInvitations(userId: number) {
    return this.http.get<UserInvitation[]>(`${this.service}/getUserInvitations/${userId}`, { responseType: "json" });
  }

  public syncUserInvitationWithProvider(userId: number) {
    return this.http.post(`${this.service}/syncUserInvitationWithProvider/${userId}`, { responseType: "json" });
  }

  public exportCredentialsAccessControlSystem(searchUserRequestModel: SearchUserRequestModel) {
    return this.http.post(`${this.service}/exportCredentialsAccessControlSystem`, searchUserRequestModel, { responseType: "blob" });
  }

  public exportDetailedAllUsersCredentials(searchUserRequestModel: SearchUserRequestModel) {
    return this.http.post(`${this.service}/exportDetailedAllUsersCredentials`, searchUserRequestModel, { responseType: "blob" });
  }
  public importCredentialsAccessControlSystem(credentials: UserCredentialsImportAcsModel[]) {
    return this.http.post(`${this.service}/importCredentialsAddedToAccessControlSystem`, credentials, { responseType: "json" });
  }

  public updateCredentialAccessControlSystemStatus(id: number, isAdded: boolean) {
    return this.http.put<any>(`${this.service}/updateCredentialAccessControlSystemStatus/${id}/${isAdded}`, { responseType: "json" });
  }

  updatePassAccessControlSystemStatus(passId: number, isAdded: boolean) {
    return this.http.put<any>(`${this.service}/updatePassAccessControlSystemStatus/${passId}/${isAdded}`, { responseType: "json" });
  }

  public getUserCredentials(searchUserCredentialRequestModel: SearchUserCredentialRequestModel) {
    return this.http.post<PagedListViewModel<UserDeviceCredential>>(`${this.service}/getUserCredentials`, searchUserCredentialRequestModel, { responseType: "json" });
  }

  public getUserPasses(searchUserCredentialRequestModel: SearchUserPassRequestModel) {
    return this.http.post<PagedListViewModel<UserPass>>(`${this.service}/getUserPasses`, searchUserCredentialRequestModel, { responseType: "json" });
  }

  public searchResendInvitations(searchUserRequestModel: SearchUserRequestModel) {
    return this.http.post<UserResendInvitationModel[]>(`${this.service}/searchResendInvitations`, searchUserRequestModel, { responseType: "json" });
  }

  public searchForSendNotifications(searchUserRequestModel: SearchUserRequestModel) {
    return this.http.post<PagedListViewModel<User>>(`${this.service}/searchForSendNotifications`, searchUserRequestModel, { responseType: "json" });
  }

  public resendInvitations(userResendInvitations: UserResendInvitationModel[]) {
    return this.http.post(`${this.service}/resendInvitations`, userResendInvitations, { responseType: "json" });
  }

  public getMobileAppVersions() {
    return this.http.get<MobileAppVersionModel[]>(`${this.service}/getMobileAppVersions`, { responseType: "json" });
  }

  public generateUsernames(roleIds: number[]) {
    return this.http.post<string[]>(`${this.service}/generateUsernames`, roleIds, { responseType: "json" });
  }

  generateAccessTokenForApiUser(userId: number) {
    return this.http.get<AuthenticationResult>(`${this.service}/generateAccessTokenForApiUser/` + userId, { responseType: "json" });
  }

  public checkEmailTemplateConfigurationForClient(isAdminUser: boolean, isMobileUser: boolean, providerCreationType: ProviderCreationTypeEnum,
    selectedClient: Client | null | undefined
  ): boolean {
    if (selectedClient && selectedClient != null) {
      if (isAdminUser) {
        if (selectedClient.eMailPreferences.sendEmailWhenAdminUserRegister) {
          //check AdminUserRegistrationEmailTemplate configured
          if (
            !selectedClient.eMailTemplateConfigurationState
              .adminUserRegistrationEmailTemplateConfigured
          ) {
            this.showErrorMessage(
              'Email template configuration is missing for client : ' +
              selectedClient.name,
              'Email cannot be sent to the user(s) because Admin User Registration Email template is not set.'
            );
            return false;
          }
          if (
            !selectedClient.eMailTemplateConfigurationState
              .adminUserRegistrationEmailSubjectTemplateConfigured
          ) {
            this.showErrorMessage(
              'Email subject template configuration is missing for client : ' +
              selectedClient.name,
              'Email cannot be sent to the user(s) because Admin User Registration Email Subject template is not set.'
            );
            return false;
          }
        }
      }
      if (isMobileUser) {
        if (selectedClient.eMailPreferences.sendEmailWhenMobileUserRegister) {
          if (providerCreationType == ProviderCreationTypeEnum.DontIssueMobileIdAndInvitation) {
            //check MobileUserRegistrationEmailTemplate configured
            if (
              !selectedClient.eMailTemplateConfigurationState
                .mobileUserRegistrationEmailTemplateConfigured
            ) {
              this.showErrorMessage(
                'Email template configuration is missing for client : ' +
                selectedClient.name,
                'Email cannot be sent to the user(s) because Mobile User Registration Email template is not set.'
              );
              return false;
            }
            if (
              !selectedClient.eMailTemplateConfigurationState
                .mobileUserRegistrationEmailSubjectTemplateConfigured
            ) {
              this.showErrorMessage(
                'Email subject template configuration is missing for client : ' +
                selectedClient.name,
                'Email cannot be sent to the user(s) because Mobile User Registration Email Subject template is not set.'
              );
              return false;
            }
          } else if (providerCreationType == ProviderCreationTypeEnum.IssueInvitationCodeAndCredential) {
            //check MobileUserRegistrationEmailWithCredentialTemplate configured
            if (
              !selectedClient.eMailTemplateConfigurationState
                .mobileUserRegistrationEmailWithCredentialTemplateConfigured
            ) {
              this.showErrorMessage(
                'Email template configuration is missing for client : ' +
                selectedClient.name,
                'Email cannot be sent to the user(s) because  Mobile User Registration With Invitation Code And Credential Email template is not set.'
              );
              return false;
            }
            if (
              !selectedClient.eMailTemplateConfigurationState
                .mobileUserRegistrationEmailWithCredentialSubjectTemplateConfigured
            ) {
              this.showErrorMessage(
                'Email subject template configuration is missing for client : ' +
                selectedClient.name,
                'Email cannot be sent to the user(s) because  Mobile User Registration With Invitation Code And Credential Email Subject template is not set.'
              );
              return false;
            }
          } else if (providerCreationType == ProviderCreationTypeEnum.IssueInvitationCodeWithoutCredential
          ) {
            //check MobileUserRegistrationEmailWithInvitationCodeTemplate configured
            if (!selectedClient.eMailTemplateConfigurationState.mobileUserRegistrationEmailWithInvitationCodeTemplateConfigured
            ) {
              this.showErrorMessage(
                'Email template configuration is missing for client : ' +
                selectedClient.name,
                'Email cannot be sent to the user(s) because  Mobile User Registration With Invitation Code Email template is not set.'
              );
              return false;
            }
            if (!selectedClient.eMailTemplateConfigurationState.mobileUserRegistrationEmailWithInvitationCodeSubjectTemplateConfigured
            ) {
              this.showErrorMessage(
                'Email subject template configuration is missing for client : ' +
                selectedClient.name,
                'Email cannot be sent to the user(s) because  Mobile User Registration With Invitation Code Email Subject template is not set.'
              );
              return false;
            }
          } else if (providerCreationType == ProviderCreationTypeEnum.IssueInvitationCodeAndCredentialAndPass) {
            if (
              !selectedClient.eMailTemplateConfigurationState
                .mobileUserRegistrationEmailWithCredentialAndPassTemplateConfigured
            ) {
              this.showErrorMessage(
                'Email template configuration is missing for client : ' +
                selectedClient.name,
                'Email cannot be sent to the user(s) because  Mobile User Registration With Invitation Code And Credential and Pass Email template is not set.'
              );
              return false;
            }
            if (
              !selectedClient.eMailTemplateConfigurationState
                .mobileUserRegistrationEmailWithCredentialAndPassSubjectTemplateConfigured
            ) {
              this.showErrorMessage(
                'Email subject template configuration is missing for client : ' +
                selectedClient.name,
                'Email cannot be sent to the user(s) because  Mobile User Registration With Invitation Code And Credential and Pass Email Subject template is not set.'
              );
              return false;
            }
          } else if (providerCreationType == ProviderCreationTypeEnum.IssueInvitationCodeAndPass
          ) {
            if (!selectedClient.eMailTemplateConfigurationState.mobileUserRegistrationEmailWithInvitationCodeAndPassTemplateConfigured
            ) {
              this.showErrorMessage(
                'Email template configuration is missing for client : ' +
                selectedClient.name,
                'Email cannot be sent to the user(s) because  Mobile User Registration With Invitation Code And Pass Email template is not set.'
              );
              return false;
            }
            if (!selectedClient.eMailTemplateConfigurationState.mobileUserRegistrationEmailWithInvitationCodeAndPassSubjectTemplateConfigured
            ) {
              this.showErrorMessage(
                'Email subject template configuration is missing for client : ' +
                selectedClient.name,
                'Email cannot be sent to the user(s) because  Mobile User Registration With Invitation Code And Pass Email Subject template is not set.'
              );
              return false;
            }
          }
        }

      }
    } else return false;
    return true;
  }

  showErrorMessage(title: string, message: string) {
    swal({
      title: title,
      text: message,
      icon: 'error',
    });
  }

  createInitialisationEvents(userId: number) {
    return this.http.post<any>(`${this.service}/createAdSyncInitialisationEvents/${userId}`, null, { responseType: "json" });
  }

  createAdSyncDeleteUsersEvents(userId: number) {
    return this.http.post<any>(`${this.service}/createAdSyncDeleteUsersEvents/${userId}`, null, { responseType: "json" });
  }
  public searchUsersForSummary(searchUserRequestModel: SearchUserRequestModel) {
    return this.http.post<UserListSummaryModel>(`${this.service}/searchUserAsSummary`, searchUserRequestModel, { responseType: "json" });
  }
}
