import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { UserblockComponent } from './sidebar/userblock/userblock.component';
import { FooterComponent } from './footer/footer.component';

import { UserService } from "@app/services/shared/user.service";

import { NotificationComponent } from './header/notification/notification.component';
import { ContentHeaderComponent } from './content-header/content-header.component';

@NgModule({
  imports: [
    SharedModule
  ],
  providers: [
    UserService
  ],
  declarations: [
    LayoutComponent,
    SidebarComponent,
    UserblockComponent,
    HeaderComponent,
    FooterComponent,
    NotificationComponent,
    ContentHeaderComponent
  ],
  exports: [
    LayoutComponent,
    SidebarComponent,
    UserblockComponent,
    HeaderComponent,
    FooterComponent,
    NotificationComponent,
    ContentHeaderComponent
  ]
})
export class LayoutModule {
}
