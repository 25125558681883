import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HealthcheckComponent } from './healthcheck.component';



@NgModule({
  declarations: [
    HealthcheckComponent
  ],
  imports: [
    CommonModule
  ]
})
export class HealthcheckModule { }
