export class ClientPasswordPolicy {
    public id: number;
    public clientId: number;
    public charDigitMin: number;
    public charLowerMin: number;
    public charSpecialMin: number;
    public charUpperMin: number;
    public lengthMax: number;
    public lengthMin: number;
    public mustNotContain: string[]
    public passwordExpirePeriod: number;
    public password:string;
    public passwordValidated:boolean;
    public passwordValidationErrors:string[];
}