export function stringIsNullOrEmpty(value: any) {
  return value === undefined || value === null || value === "" || value === "undefined";
}

export function isNumber(value: string) {
  if (value.match(/^-?\d+$/)) {
    return true;
    //valid integer (positive or negative)
  } else if (value.match(/^\d+\.\d+$/)) {
    return false;
    //valid float
  } else {
    //not valid number
  }
}

export function replaceAll(originalText: string, textToReplace: string, newText: string) {
  if (stringIsNullOrEmpty(originalText)) {
    return originalText;
  }
  return originalText.split(textToReplace).join(newText);
}

export function isNullOrWhitespace(input: any) {
  if (typeof input === 'undefined' || input == null) return true;
  return input.replace(/\s/g, '').length < 1;
}
