import { Injectable } from "@angular/core";
import { AuthenticatedModel } from "@app/models/authentication/authenticated.model";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { TokenService } from "./token.service";
import { UserUpdatePasswordViewModel } from "@app/models/administration/userUpdatePasswordViewModel";
import {Observable} from "rxjs";
import { Role } from "@app/models/shared/role.enum";

@Injectable({ providedIn: "root" })
export class UserService {
  private authenticatedUser: AuthenticatedModel | null;
  public userBlockVisible: boolean;
  private service = 'UserService';

  constructor(private http: HttpClient,
    private router: Router,
    private tokenService: TokenService) {
    // initially visible
    this.userBlockVisible = true;
  }

  public getVisibility() {
    return this.userBlockVisible;
  }

  public toggleVisibility() {
    this.userBlockVisible = !this.userBlockVisible;
  }

  // tslint:disable-next-line: member-ordering
  public setUser = new Observable<AuthenticatedModel>((observer) => {
    const helper = new JwtHelperService();
    const user = new AuthenticatedModel();

    // get the embbeded information from token and save logged in user info
    // we check if the page refreshed and component cleaned. If yes we repopulate the user
    const rawToken = this.tokenService.get('token');

    if (!rawToken) {
      this.router.navigate(['/login']);
      return;
    }

    Object.assign(user, JSON.parse(helper.decodeToken(rawToken).sub));

    this.authenticatedUser = user;

    observer.next(user);
    observer.complete();
  });

  // tslint:disable-next-line: member-ordering
  public get = new Observable<AuthenticatedModel>((observer) => {
    const options = { responseType: 'text' as 'text' };

    if (!this.authenticatedUser) {
      this.setUser.subscribe((result) => {
        this.authenticatedUser = result;

        this.http.get(`api/${this.service}/loadProfilePictureFromFileName/${this.authenticatedUser.profilePictureName}`, options).
          subscribe(
            (profilePictureResponse) => {
              if (this.authenticatedUser)
                this.authenticatedUser.profilePictureUrl = profilePictureResponse;
              observer.next(this.authenticatedUser ?? undefined);
            },
            (error): void => {
              observer.next(this.authenticatedUser ?? undefined);
            });
      });
    }

    this.http.get(`api/${this.service}/loadProfilePictureFromFileName/${this.authenticatedUser?.profilePictureName}`, options).
      subscribe(
        (profilePictureResponse) => {
          if (this.authenticatedUser)
            this.authenticatedUser.profilePictureUrl = profilePictureResponse;
          observer.next(this.authenticatedUser ?? undefined);
        },
        (error): void => {
          observer.next(this.authenticatedUser ?? undefined);
        });
  });

  public getLoggedInUser() {
    return this.authenticatedUser ?? new AuthenticatedModel();
  }

  public uploadImage(image) {
    return this.http.post(`api/${this.service}/upload-image/`, image, { reportProgress: true, observe: 'events' });
  }

  public updatePassword(userUpdatePasswordViewModel: UserUpdatePasswordViewModel) {
    return this.http.post(`api/${this.service}/update-password/`, userUpdatePasswordViewModel);
  }

  public getProfilePictureUrl() {
    return this.authenticatedUser?.profilePictureUrl;
  }
  public logoutUser() {
    this.authenticatedUser = null;
  }

  public isUserADAuthenticated() {
    return this.http.get<boolean>(`api/${this.service}/IsUserADAuthenticated`);
  }
}
