import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Role } from '@app/models/authorization-management/role-admin/role.model';
import { SearchRequestModel } from '@app/models/shared/searchrequest.model';
import { PagedListViewModel } from '@app/models/shared/pagedlist.model';
import { Bundle } from '@app/models/authorization-management/bundle-admin/bundle.model';
import { PageItem } from '@app/models/authorization-management/page-admin/page-item.model';
import { MenuItem } from '@app/models/authorization-management/menu-admin/menu-item.model';
import { Observable, Subject } from 'rxjs';
import { Menu } from '@app/models/authorization-management/menu-admin/menu.model';
import { MenuItemType } from '@app/models/authorization-management/menu-admin/menuitem.type.enum';
import { MenuItemOrder } from '@app/models/authorization-management/menu-admin/menuitemorder.model';
import { Environment } from '@env/environment.global';

@Injectable({ providedIn: 'root' })
export class AuthorizationManagementService {

  private service = 'api/AuthorizationManagement';
  public menuIconFileUploadService = "";

  public menuUpdated = new Subject();
  public userMenuChanged = new Subject();

  private userMenu: Menu | undefined;

  constructor(private http: HttpClient, environment: Environment) {
    this.menuIconFileUploadService = environment.getBaseUrl() + this.service + '/upload'
  }

  getBundles() {
    return this.http.get<Bundle[]>(`${this.service}/getBundles`, {
      responseType: 'json',
    });
  }

  getRoles() {
    return this.http.get<Role[]>(`${this.service}/getRoles`, {
      responseType: 'json',
    });
  }

  searchRoles(searchRequestModel: SearchRequestModel) {
    return this.http.post<PagedListViewModel<Role>>(
      `${this.service}/searchRoles`,
      searchRequestModel,
      { responseType: 'json' }
    );
  }

  saveRole(roleModel: Role) {
    return this.http.post(`${this.service}/saveRole`, roleModel, {
      responseType: 'json',
    });
  }

  deleteRole(roleId: number) {
    return this.http.delete<any>(`${this.service}/deleteRole/${roleId}`);
  }

  getUserSelectableRoles() {
    return this.http.get<Role[]>(`${this.service}/getUserSelectableRoles`, {
      responseType: 'json',
    });
  }
  public getMenuItems() {
    return this.http.get<MenuItem[]>(`${this.service}/GetMenuItems`, { responseType: "json" });
  }


  public saveMenuItems(menuItems: MenuItem[]) {
    return this.http.post(`${this.service}/saveMenuItems`, menuItems, { responseType: "json" });
  }
  public saveBundle(bundle: Bundle) {
    return this.http.post(`${this.service}/saveBundle`, bundle, { responseType: "json" });
  }
  public deleteBundle(bundleId: number) {
    return this.http.delete(`${this.service}/deleteBundle/${bundleId}`, { responseType: "json" });
  }

  searchBundles(searchRequest: SearchRequestModel) {
    return this.http.post<PagedListViewModel<Bundle>>(`${this.service}/searchBundles`, searchRequest, { responseType: 'json' });
  }

  public getPageItems() {
    return this.http.get<PageItem[]>(`${this.service}/getPageItems`, { responseType: "json" });
  }

  public getPageItemsWithElementsBySearchTerm(searchRequest: SearchRequestModel) {
    return this.http.post<PageItem[]>(`${this.service}/getPageItemsWithElementsBySearchTerm`, searchRequest, { responseType: "json" });
  }

  public clearMenu() {
    this.userMenu = undefined;
  }

  public getUserMenu(fromRemote: boolean = false): Observable<Menu> {
    if (this.userMenu && fromRemote == false) {
      return new Observable((observer) => {
        observer.next(this.userMenu);
      });
    } else {
      return new Observable((observer) => {
        this.http.get<Menu>(`${this.service}/getUserMenu`)
          .subscribe(
            result => {
              this.userMenu = result;
              observer.next(result);
              this.userMenuChanged.next(result);
            },
            error => observer.error(error),
            () => observer.complete());
      });
    }
  }

  public getAllMenu(): Observable<Menu> {
    return this.http.get<Menu>(`${this.service}/getAllMenu`);
  }

  public deleteMenuItem(itemId: number): Observable<any> {
    return this.http.delete<any>(`${this.service}/deleteMenuItem/${itemId}`, { responseType: "json" });
  }

  public updateMenuItem(menuItem: MenuItem) {
    return this.http.post<MenuItem>(`${this.service}/updateMenuItem`, menuItem);
  }

  public addMenuItem(menuItem: MenuItem): Observable<MenuItem> {
    return this.http.post<MenuItem>(`${this.service}/addMenuItem`, menuItem);
  }

  public updateItemOrders(itemOrderList: MenuItemOrder[]) {
    let jsonData = JSON.stringify(itemOrderList);
    return this.http.post(`${this.service}/updateMenuItemOrders`, jsonData);
  }

  public createDefaultMenuItem(displayText: string, type: MenuItemType): MenuItem {
    let menuItem = new MenuItem();
    menuItem.menuItemType = type;
    menuItem.displayText = displayText;
    menuItem.itemOrder = 0;
    return menuItem;
  }

  public getMenuPageItemByRoute(route: string): PageItem | null {
    if (this.userMenu) {
      this.userMenu.menuGroups.forEach(sep => {
        sep.childMenuItems.forEach(mi => {
          if (mi.menuItemType === MenuItemType.Group) {
            if (mi.childMenuItems && mi.childMenuItems.length > 0) {
              mi.childMenuItems.forEach(i => {
                if (i.pageItem && i.pageItem.url.toLowerCase() === route.toLowerCase()) {
                  return i.pageItem;
                }
              });
            }
          } else {
            if (mi.pageItem && mi.pageItem.url.toLowerCase() === route.toLowerCase()) {
              return mi.pageItem;
            }
          }
        });
      });
    }
    return null;
  }
}
