import { Component, OnInit } from '@angular/core';
import { NotificationService } from '@app/services/notification/notification.service';
import { Router } from '@angular/router';
import { Notification as NotificationModel } from '@app/models/notification/notification.model';
import { TokenService } from '@app/services/shared/token.service';
import { NotificationType } from '@app/models/notification/notification.types';
import { Subscription } from 'rxjs';

@Component({
  selector: '[notification]',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  public notificationsCount = 0;
  public notificationsLeft = 0;
  public notifications: NotificationModel[] | null = null;
  public fetchingNotifications = false;
  private notificationSubscription: Subscription;

  constructor(
    private tokenService: TokenService,
    public notificationService: NotificationService,
    private router: Router) {
  }

  public ngOnInit() {
    this.notificationSubscription = this.notificationService.notificationReceived.subscribe(result => {
      if (result.type != NotificationType.TaskProggress)
        this.notificationsCount++;
    });

    this.getNotificationsCount();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.notificationSubscription.unsubscribe();
  }
  
  public redirectTo(uri) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigateByUrl(uri));
  }

  private getNotificationsCount() {
    this.notificationService.getUnseenNotificationsCount().subscribe(
      (result) => {
        this.notificationsCount = result;
      });
  }

  public notificationClicked() {
    this.notifications = null;

    if (this.notificationsCount === 0) {
      return;
    }

    this.fetchingNotifications = true;
    this.notificationService.getUnseenNotifications().subscribe(
      (result) => {
        this.fetchingNotifications = false;
        this.notifications = result;
        this.notificationsLeft = this.notificationsCount - result.length;
        this.notificationService.markNotificationsAsSeen().subscribe(
          markingResult => this.getNotificationsCount());
      });
  }

}
