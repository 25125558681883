<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
    <!-- START navbar header-->
    <div class="navbar-header">
        <a class="navbar-brand" href="/">
            <div class="brand-logo">
                <img class="img-fluid" src="assets/img/logoMyAccessHorizontalNegative.png" alt="App Logo" />
            </div>
            <div class="brand-logo-collapsed">
                <img class="img-fluid" src="assets/img/myAccessCog.png" alt="App Logo" />
            </div>
        </a>
    </div>
    <!-- END navbar header-->
    <!-- START Left navbar-->


    <ul class="navbar-nav mr-auto flex-row">
        <li class="nav-item">
            <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
            <a class="nav-link d-none d-md-block d-lg-block d-xl-block" trigger-resize="" (click)="toggleCollapsedSideabar()" *ngIf="!isCollapsedText()">
                <em class="fa fa-navicon"></em>
            </a>
            <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
            <a class="nav-link sidebar-toggle d-md-none" (click)="settings.layout.asideToggled =! settings.layout.asideToggled; $event.stopPropagation()">
                <em class="fa fa-navicon"></em>
            </a>
        </li>
        <!-- START User avatar toggle-->
        <li class="nav-item d-none d-md-block">
            <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
            <a class="nav-link" (click)="toggleUserBlock($event)">
                <em class="icon-user"></em>
            </a>
        </li>
        <!-- END User avatar toggle-->
        <!-- START lock screen-->
        <li class="nav-item d-none d-md-block" *ngIf="!isSsoUser()">
            <a class="nav-link" title="Lock screen" href="#" (click)="lockClicked(); $event.preventDefault()">
                <em class="icon-lock"></em>
            </a>
        </li>
        <!-- END lock screen-->
    </ul>
    <!-- END Left navbar-->
    <!-- START Right Navbar-->
    <ul class="navbar-nav flex-row">
        <!-- Fullscreen (only desktops)-->
        <li class="nav-item d-none d-md-block">
            <a class="nav-link" #fsbutton (click)="toggleFullScreen($event)">
                <em class="fa fa-expand"></em>
            </a>
        </li>

        <!-- START Alert menu-->
        <li notification class="nav-item dropdown dropdown-list" dropdown>
        </li>
        <!-- END Alert menu-->
    </ul>
    <!-- END Right Navbar-->

</nav>
<!-- END Top Navbar-->
