import { LayoutComponent } from '../layout/layout.component';
import { LockComponent } from './pages/lock/lock.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { AuthenticationGuard } from '../shared/guards/authentication.guard';
import { LoginComponent } from './login/login.component';
import { RecoverComponent } from './pages/password-recovery/recover.component';
import { ResetPasswordComponent } from './pages/password-recovery/reset-password.component';
import { HealthcheckComponent } from '@app/healthcheck/healthcheck.component';
import { Error401Component } from './pages/error401/error401.component';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { SsoLogoutComponent } from "@app/routes/pages/sso-logout/sso-logout.component";

export const routes = [
	{
		path: '',
		component: LayoutComponent,
		canActivate: [AuthenticationGuard],
		children: [
			{ path: '', redirectTo: 'reports', pathMatch: 'full' },
			{
				path: 'administration',
				loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
				canActivate: [AuthenticationGuard]
			},
			{
				path: 'administration',
				loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule),
				canActivate: [AuthenticationGuard]
			},
			{
				path: 'administration',
				loadChildren: () => import('./credential/credential.module').then(m => m.CredentialModule),
				canActivate: [AuthenticationGuard]
			},
			{
				path: 'authorization-management',
				loadChildren: () => import('./authorization-management/authorization-management.module').then(m => m.AuthorizationmanagementModule),
				canActivate: [AuthenticationGuard]
			},
			{
				path: 'reports',
				loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
				canActivate: [AuthenticationGuard]
			},
			{
				path: 'help',
				loadChildren: () => import('./help/help.module').then(m => m.HelpModule),
				canActivate: [AuthenticationGuard]
			},
			{
				path: 'pass-management',
				loadChildren: () => import('./pass-management/pass-management.module').then(m => m.PassManagementModule),
				canActivate: [AuthenticationGuard]
			},
			{
				path: 'event-management',
				loadChildren: () => import('./event-management/event-management.module').then(m => m.EventManagementModule),
				canActivate: [AuthenticationGuard]
			},
			{
				path: 'wallet-management',
				loadChildren: () => import('./wallet-management/wallet-management.module').then(m => m.WalletManagementModule),
				canActivate: [AuthenticationGuard]
			}
		]
	},
	// Not lazy-loaded routes
	{ path: 'login', component: LoginComponent },
  {	// Needed for handling redirect after login
    path: 'auth',
    component: MsalRedirectComponent
  },
  {
    path: 'sso-logout',
    component: SsoLogoutComponent
  },
	{ path: 'recover', component: RecoverComponent },
	{ path: 'reset-password', component: ResetPasswordComponent },
	{ path: 'lock', component: LockComponent },
	{ path: 'maintenance', component: MaintenanceComponent },
	{ path: '401', component: Error401Component },
	{ path: '404', component: Error404Component },
	{ path: '500', component: Error500Component },
	{ path: 'healthcheck', component: HealthcheckComponent, data: { 'disablePreloader': true } },

	// Not found
	{ path: '**', redirectTo: '404' }
];
