import { Injectable } from '@angular/core';
import { Menu } from '@app/models/authorization-management/menu-admin/menu.model';
import { clonify } from '@app/shared/utils/object.utils';
import { AuthorizationManagementService } from '../authorization-management/authorization-management.service';

@Injectable()
export class MenuService {
  private menu: Menu;

  public getMenu() {
    return this.menu;
  }
  public setMenu(menu: Menu) {
    this.menu = menu;
  }

  public getMenuDetailsByRoute(url: string) {
    if (this.menu) {
      const menuGroupItems = [
        ...this.menu.menuGroups,
      ];
      const menuItems = ([] as any).concat(
        ...menuGroupItems.map((g) => g.childMenuItems || [])
      );
      const filteredItems = menuItems.filter(
        (m: any) => m.url === url
      );

      return filteredItems.length > 0 ? filteredItems[0] : null;
    }
    return null;
  }
}
