import { MenuItem } from "../menu-admin/menu-item.model";
import { PageElement } from "./page-element.model";

export class PageItem {
    public id: number;
    public name: string;
    public url: string;
    public predefined: boolean;
    public pageElements: PageElement[]
    public menuItem: MenuItem
    //tree props
    public isSelected: boolean;
    public authorizationLevelId: number;
    public collapsed: boolean = false;
}