import { Client } from '@app/models/client/client.model';
import { UserApp } from '@app/models/app/user-app';
import { UserSampleInfo } from './usersampleinfo.model';
import { ProviderCreationTypeEnum } from './provider-creation-type.enum';
import { UserRole } from '@app/models/user/user-role.model';

export class User {
  public id: number;
  public clientId: number;
  public clientIsTenant: boolean;
  public isActive: boolean;
  public userName: string;
  public password: string;
  public profilePicture?: string;
  public profilePictureUrl?: string;
  public name: string;
  public surname: string;
  public email: string;
  public client: Client;
  public sampleInfo: UserSampleInfo;
  public prefix: string;
  public middleName: string;
  public suffix: string;
  public apps: UserApp[];
  public providerCreationType: ProviderCreationTypeEnum;
  public initialCredentialNumber: string;
  public passTemplateId: string;
  public imeiNumbers: string[];
  public userRoles: UserRole[];
  public isSsoUser: boolean;
  public isWalletUser: boolean;
  constructor() {
    this.apps = [];
    this.imeiNumbers = [];
    this.userRoles = [];
  }
}
