import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { SettingsService } from "@app/services/shared/settings.service";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationModel } from "@app/models/authentication/authentication.model";
import { AuthenticationService } from "@app/services/login/authentication.service";
import { IdTokenClaims, PromptValue } from '@azure/msal-common';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest, SsoSilentRequest } from '@azure/msal-browser';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SsoAuthenticationResult } from '@app/models/authentication/sso-authentication-result.model';
import { Environment } from '@env/environment.global';
import swal from "sweetalert";

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
};

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  public valForm: FormGroup;
  public authentication = new AuthenticationModel();
  public isLoginProcessing: boolean;
  public isSsoLoginProcessing: boolean;
  private readonly _destroying$ = new Subject<void>();
  public ssoLoginProccessMessage: string = "Sso Login Proccess Started..."

  constructor(public settings: SettingsService,
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService) {
  }

  @ViewChild('rootElement', { static: true })
  public rootElement: ElementRef;

  public ngOnInit() {
    this.valForm = this.fb.group({
      'userName': [null, Validators.required],
      'password': [null, Validators.required],
    });
    this.prepareForSso();
  }
  prepareForSso() {
    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        console.log("login proccess completed.");
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE
          || msg.eventType === EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE
          || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.log("SSO login failed.");
        this.handleSSOFailure(result);
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
          || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
          || msg.eventType === EventType.SSO_SILENT_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        this.isSsoLoginProcessing = true;
        this.authenticationService.sso = true;
        this.ssoLoginProccessMessage = "Sso Login Proccess Completed. Please Wait...";
        let payload = result.payload as AuthenticationResult;
        let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;
        console.log(payload);

        this.authService.instance.setActiveAccount(payload.account);
        var ssoResult = new SsoAuthenticationResult();
        ssoResult.idToken = payload.idToken;
        ssoResult.idp = idtoken.idp;
          this.authenticationService.authenticate(ssoResult, false, true)
            .subscribe()
            .add(_ => {
            this.isLoginProcessing = false;
            this.authenticationService.sso = false;
            this.isSsoLoginProcessing = false;
        });

        return result;
      });
  }
  handleSSOFailure(result: EventMessage) {
    var message = result.error?.message;
    if (result.error && result.error.message.indexOf('AADB2CCODEXXXX') > -1) {
      message="manipulate message by AADB2CCODE"
    };

    swal({
      title: 'Something went wrong during SSO login!',
      text: message,
      dangerMode: true
    });
  }

  public submitForm() {
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }

    if (this.valForm.valid) {
      this.authentication.username = this.valForm.value.userName;
      this.authentication.password = this.valForm.value.password;
      this.isLoginProcessing = true;
      this.authenticationService.authenticate(this.authentication).subscribe(() => {
      }).add(_ => {
        this.isLoginProcessing = false;
        this.authenticationService.sso = false;
      });
    }
  }

  loginWithSso(userFlowRequest?: RedirectRequest | PopupRequest) {
    this.isSsoLoginProcessing = true;
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}