import { UserService } from "../shared/user.service";
import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";
import { AuthenticationService } from "./authentication.service";
import { AuthenticationModel } from "@app/models/authentication/authentication.model";
import { AuthenticatedModel } from "@app/models/authentication/authenticated.model";
import { AuthenticationType } from "@app/models/authentication/authentication-type";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class UserLockService {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private domSanitizer: DomSanitizer) {
  }

  public isLocked(): boolean {
    return this.locked;
  }

  public lock(returnUrl: string) {
    if (this.locked) {
      return;
    }

    const authenticatedModel: AuthenticatedModel = this.userService.getLoggedInUser() ?? new AuthenticatedModel();

    this.username = authenticatedModel.userName;
    this.profilePictureUrl = authenticatedModel.profilePictureUrl;
    this.locked = true;
    this.returnUrl = returnUrl;

    this.authenticationService.clearSessionData();
  }

  public unlock(password: string) {
    if (!this.locked) {
      return
    }

    const authenticationModel: AuthenticationModel = new AuthenticationModel();
    authenticationModel.password = password;
    authenticationModel.username = this.username;

    this.authenticationService.authenticate(authenticationModel, true).subscribe(() => {
      this.router.navigateByUrl(this.returnUrl);
      this.clear();
    });
  }

  public getProfilePictureUrl(): SafeResourceUrl {
    return this.profilePictureUrl;
  }

  public clear() {
    this.removeItem("userName");
    this.removeItem("authenticationType");
    this.removeItem("domainName");
    this.removeItem("profilePicture");
    this.removeItem("locked");
    this.removeItem("returnUrl");
  }

  get username(): string {
    return this.getItem("userName")
  }

  set username(value: string) {
    this.saveItem("userName", value);
  }

  get authenticationType(): AuthenticationType {
    return AuthenticationType[this.getItem("authenticationType")];
  }

  set authenticationType(value: AuthenticationType) {
    this.saveItem("authenticationType", AuthenticationType[value]);
  }

  get domainName(): string {
    return this.getItem("domainName")
  }

  set domainName(value: string) {
    this.saveItem("domainName", value);
  }

  get profilePictureUrl(): string {
    return this.getItem("profilePictureUrl")
  }

  set profilePictureUrl(value: string) {
    this.saveItem("profilePictureUrl", value);
  }

  get locked(): boolean {
    return this.getItem("locked") == "true";
  }

  set locked(value: boolean) {
    this.saveItem("locked", value.toString());
  }

  get returnUrl(): string {
    return this.getItem("returnUrl")
  }

  set returnUrl(value: string) {
    this.saveItem("returnUrl", value);
  }

  private saveItem(key: string, value: string) {
    localStorage.setItem("userlock_" + key, value);
  }

  private getItem(key: string): string {
    return localStorage.getItem("userlock_" + key) ?? '';
  }

  private removeItem(key: string) {
    localStorage.removeItem("userlock_" + key);
  }
}
