import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TokenService } from "../shared/token.service";
import { AuthenticationModel } from "@app/models/authentication/authentication.model";
import { AuthenticatedModel } from "@app/models/authentication/authenticated.model";
import { UserService } from "../shared/user.service";
import { Observable, Observer } from "rxjs";
import { AuthenticationResult } from '@app/models/authentication/authentication-result.model';
import swal from "sweetalert";
import {
  AuthorizationManagementService
} from "@app/services/authorization-management/authorization-management.service";
import { PageElement } from "@app/models/authorization-management/page-admin/page-element.model";
import { Bundle } from "@app/models/authorization-management/bundle-admin/bundle.model";
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from "@azure/msal-angular";
import { InteractionType } from "@azure/msal-browser";
import { NotificationService } from "../notification/notification.service";
import { Environment } from "@env/environment.global";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  public static authorizedBundlesLocalStorageKey = "authorizedBundles";
  public static authServiceLocalStorageKeyPrefix = "auth_";
  private service = "AuthenticationService";

  constructor(
    private http: HttpClient,
    private router: Router,
    private tokenService: TokenService,
    private userService: UserService,
    private authorizationManagementService: AuthorizationManagementService,
    private route: ActivatedRoute,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private notificationService: NotificationService) {
  }

  private executeAuthenticationAction(preventHomeNavigation: boolean, token: string, observer: Observer<AuthenticatedModel>) {
    this.tokenService.set('token', token);
    this.userService.setUser.subscribe(() => {
      this.getAuthorizedBundles().subscribe(() => {
        observer.next(this.userService.getLoggedInUser() ?? new AuthenticatedModel());
        if (!preventHomeNavigation) {
          const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
          this.router.navigateByUrl(returnUrl);
        }
      });
    });
  }

  private executeErrorAction(error: any, observer: Observer<AuthenticatedModel>) {
    if(error == null) {
      observer.error(null);
      return;
    }

    let message;
    if (error.status) {
      if (error.status === 404) {
        message = '404 - Authentication service is not accessible';
      } else if (error.status === 500) {
        message = '500 - Please try again later';
      } else {
        message = `${error.error}. Please try again later.`;
      }
    }
    const returnUrl = this.route.snapshot.queryParams['returnUrl'];

    swal({
      title: 'Something went wrong',
      text: message,
      dangerMode: true
    }).then(_ => {
      if (returnUrl) {
        this.router.navigateByUrl(returnUrl).then(_ => {
          if (typeof error == 'object')
          observer.error(error);
        });
      } else {
        observer.error(error);
      }
    });
  }

  public authenticate(authentication: any, preventHomeNavigation: boolean = false, sso: boolean = false): Observable<AuthenticatedModel> {
    let method = sso ? "AuthenticateWithSsoResult" : "Authenticate"
    return new Observable((observer) => {
      this.tokenService.clear();
      localStorage.removeItem(AuthenticationService.authorizedBundlesLocalStorageKey);
      this.http.post<AuthenticationResult>(`api/${this.service}/${method}`, authentication)
        .subscribe(
          result => {
            if (!result.needsLogout) {
              this.executeAuthenticationAction(preventHomeNavigation, result.token, observer);
            } else {
              this.executeNeedsLogoutAction(preventHomeNavigation, authentication, sso, observer);
            }
          },
          error => {
            this.executeErrorAction(error, observer);
          }
        );
    });
  }

  public logout() {
    this.http.post(`api/${this.service}/logout`, {})
      .subscribe()
      .add(() => {
        this.clearSessionData();
        this.authorizationManagementService.clearMenu();
        this.notificationService.closeConnection();
        this.router.navigate(["/login"]);
      });
    if (this.loggedWithSso())
      this.logoutSso();
  }
  logoutSso() {
    const activeAccount =
      this.authService.instance.getActiveAccount() ||
      this.authService.instance.getAllAccounts()[0];

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        account: activeAccount,
      });
    } else {
      this.authService.logoutRedirect({
        account: activeAccount,
        authority: Environment.getSsoPolicy().authorities.logout.authority
      });
    }
  }

  public clearSessionData() {
    this.userService.logoutUser();
    this.tokenService.clear();
    localStorage.removeItem(AuthenticationService.authorizedBundlesLocalStorageKey);
  }

  private executeNeedsLogoutAction(preventHomeNavigation: boolean,
    authentication: AuthenticationModel, sso: boolean = false,
    observer: Observer<AuthenticatedModel>) {
    let method = sso ? "AuthenticateWithSsoResult" : "Authenticate"
    swal({
      title: 'Active session detected',
      text: 'You have logged in at another location. Your other sessions will be terminated!',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'No',
          visible: true,
          closeModal: true
        },
        confirm: {
          text: 'Yes',
          value: true,
          visible: true,
          className: 'bg-danger',
          closeModal: true
        }
      }
    }).then((isConfirm) => {
      if (isConfirm) {
        const params = new HttpParams()
          .set('forceLogin', true);
        this.http.post<AuthenticationResult>(`api/${this.service}/${method}`, authentication, { params })
          .subscribe(
            innerResult => {
              this.executeAuthenticationAction(preventHomeNavigation, innerResult.token, observer);
            },
            innerError => {
              this.executeErrorAction(innerError, observer);
            });
      } else {
        observer.error(null);
      }
    });
  }

  public getAuthorizedBundles() {
    return new Observable((observer) => {
      const bundles = localStorage.getItem(AuthenticationService.authorizedBundlesLocalStorageKey);

      if (bundles != null) {
        observer.next(JSON.parse(bundles));
      } else {
        this.http.get<Bundle[]>(`api/${this.service}/getAuthorizedBundles`, { responseType: "json" })
          .subscribe((result) => {
            localStorage.setItem(AuthenticationService.authorizedBundlesLocalStorageKey, JSON.stringify(result));
            observer.next(result);
          });
      }
    });
  }
  public getAuthorizedBundlesFromLocalStorage(): Bundle[] | undefined {
    const bundles = localStorage.getItem(AuthenticationService.authorizedBundlesLocalStorageKey);

    if (bundles != null) {
      return JSON.parse(bundles) as Bundle[];
    }
  }

  public loggedWithSso(): boolean {
    return this.sso;
  }

  get sso(): boolean {
    return this.getItem("sso") == "true";
  }

  set sso(value: boolean) {
    this.saveItem("sso", value.toString());
  }

  private saveItem(key: string, value: string) {
    localStorage.setItem(AuthenticationService.authServiceLocalStorageKeyPrefix + key, value);
  }

  private getItem(key: string): string {
    return localStorage.getItem(AuthenticationService.authServiceLocalStorageKeyPrefix + key) ?? '';
  }
}
