import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRouteSnapshot, RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { AccordionModule } from "ngx-bootstrap/accordion";
import { AlertModule } from "ngx-bootstrap/alert";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { RatingModule } from "ngx-bootstrap/rating";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { SparklineDirective } from "./directives/sparkline/sparkline.directive";
import { NumberLimitDirective } from "./directives/input/numberlimit.directive";
import { DecimalLimitDirective } from "./directives/input/decimallimit.directive";
import { AlphaNumericOnlyDirective } from "./directives/input/alphanumericonly.directive";
import { RegexOnlyDirective } from "./directives/input/regexonly.directive";
import { EasypiechartDirective } from '@app/shared/directives/easypiechart/easypiechart.directive';

import { ColorsService } from "../services/shared/colors.service";
import { CheckallDirective } from "./directives/checkall/checkall.directive";
import { NowDirective } from "./directives/now/now.directive";
import { ScrollableDirective } from "./directives/scrollable/scrollable.directive";

import { PopoverModule } from "ngx-bootstrap/popover";
import { FlotDirective } from "./directives/flot/flot.directive";
import { VectormapDirective } from "./directives/vectormap/vectormap.directive";
import { JqcloudDirective } from "./directives/jqcloud/jqcloud.directive";

import { GuardsModule } from "./guards/guards.module";
import { InterceptorsModule } from "./interceptors/interceptors.module";
import { ModalContentComponent } from "./components/modal/modalcontent.component";
import { CustomFileUploadComponent } from "./components/customfileupload/customfileupload.component";
import { FileUploadModule } from "ng2-file-upload";
import { NgxCaptchaModule } from "ngx-captcha";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { SafeUrlPipe } from "@app/shared/pipes/safe-url.pipe";
import { SafeHtmlPipe } from "@app/shared/pipes/safe-html.pipe";
import {ToastrModule} from "ngx-toastr";
import { TagInputModule } from '@vpetrusevici/ngx-chips';
import { NgxSelectModule } from 'ngx-select-ex';
import { CronMakerModule } from './components/cron-maker/cron-maker.module';
import { ClientSelectComponent } from './components/client-select/client-select.component';
import { PreventKeysDirective } from "./directives/input/preventkeys.directive";
import { TrimDirective } from "./directives/input/trim.directive";
import { RadioSwitchComponent } from "./components/radio-switch/radioswitch.component";
import { AppLoadingComponent } from "./components/app-loading/app-loading.component";
import { HasPermissionsDirective } from "@app/core/authorization/has.permissions.directive";
import { DisableDirective } from "./directives/appdisable/appdisable.directive";

// https://angular.io/styleguide#!#04-10
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    GuardsModule,
    InterceptorsModule,
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    RatingModule.forRoot(),
    TabsModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    TypeaheadModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      closeButton: true
    }),
    FileUploadModule,
    NgxCaptchaModule,
    NgxSelectModule,
    CronMakerModule,
    TagInputModule
  ],
  providers: [ColorsService],
  declarations: [
    HasPermissionsDirective,
    FlotDirective,
    SparklineDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    NumberLimitDirective,
    DecimalLimitDirective,
    AlphaNumericOnlyDirective,
    RegexOnlyDirective,
    ModalContentComponent,
    CustomFileUploadComponent,
    SpinnerComponent,
    SafeUrlPipe,
    SafeHtmlPipe,
    EasypiechartDirective,
    ClientSelectComponent,
    PreventKeysDirective,
    TrimDirective,
    RadioSwitchComponent,
    AppLoadingComponent,
    DisableDirective
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    AccordionModule,
    AlertModule,
    ButtonsModule,
    CarouselModule,
    CollapseModule,
    BsDatepickerModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    ProgressbarModule,
    RatingModule,
    TabsModule,
    TimepickerModule,
    TooltipModule,
    PopoverModule,
    TypeaheadModule,
    ToastrModule,
    FlotDirective,
    SparklineDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    NumberLimitDirective,
    DecimalLimitDirective,
    AlphaNumericOnlyDirective,
    RegexOnlyDirective,
    GuardsModule,
    InterceptorsModule,
    ModalContentComponent,
    CustomFileUploadComponent,
    NgxCaptchaModule,
    SpinnerComponent,
    SafeUrlPipe,
    SafeHtmlPipe,
    EasypiechartDirective,
    ClientSelectComponent,
    TagInputModule,
    PreventKeysDirective,
    TrimDirective,
    RadioSwitchComponent,
    AppLoadingComponent,
    HasPermissionsDirective,
    DisableDirective
  ]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
