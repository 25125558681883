import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
declare var $: any;

import { SettingsService } from "./services/shared/settings.service";
import { TokenService } from "./services/shared/token.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  @HostBinding('class.layout-fixed')
  public get isFixed() { return this.settings.getLayoutSetting('isFixed'); };
  @HostBinding('class.aside-collapsed')
  public get isCollapsed() { return this.settings.getLayoutSetting('isCollapsed'); };
  @HostBinding('class.layout-boxed')
  public get isBoxed() { return this.settings.getLayoutSetting('isBoxed'); };
  @HostBinding('class.layout-fs')
  public get useFullLayout() { return this.settings.getLayoutSetting('useFullLayout'); };
  @HostBinding('class.hidden-footer')
  public get hiddenFooter() { return this.settings.getLayoutSetting('hiddenFooter'); };
  @HostBinding('class.layout-h')
  public get horizontal() { return this.settings.getLayoutSetting('horizontal'); };
  @HostBinding('class.aside-float')
  public get isFloat() { return this.settings.getLayoutSetting('isFloat'); };
  @HostBinding('class.offsidebar-open')
  public get offsidebarOpen() { return this.settings.getLayoutSetting('offsidebarOpen'); };
  @HostBinding('class.aside-toggled')
  public get asideToggled() { return this.settings.getLayoutSetting('asideToggled'); };
  @HostBinding('class.aside-collapsed-text')
  public get isCollapsedText() { return this.settings.getLayoutSetting('isCollapsedText'); };

  constructor(private router: Router,
    public settings: SettingsService,
    private tokenService: TokenService) {

  }

  public ngOnInit() {
  }

  public ngOnDestroy(): void { this.tokenService.clear(); }
}
