import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class TokenService {
  private token = "token";

  public clear() {
    sessionStorage.removeItem(this.token);
  }

  public exists(tokenName) {
    return !!this.get(tokenName);
  }

  public get(tokenName): string  {
    return sessionStorage.getItem(tokenName) ?? '';
  }

  public set(storageName:string, item: string) {
    sessionStorage.setItem(storageName, item);
  }

  public remove(storageName: string) {
    sessionStorage.removeItem(storageName);
  }
}
