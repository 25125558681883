<div class="wrapper">
  <div class="block-center mt-4 wd-xl" #rootElement>
    <!-- START card-->
    <div class="card card-flat">
      <div class="card-header text-center bg-dark">
        <a href="#">
          <img class="block-center rounded" src="assets/img/logoMyAccessHorizontalNegative.png" alt="Image"/>
        </a>
      </div>
      <div class="card-body">
        <div *ngIf="!isSsoLoginProcessing">
          <p class="text-center py-2">SIGN IN TO CONTINUE</p>
          <form [formGroup]="valForm" class="form-validate mb-3" role="form" name="loginForm" novalidate=""
                (submit)="submitForm()">
            <div class="username-password-div">
              <div class="form-group">
                <div class="input-group with-focus">
                  <input class="form-control border-right-0" id="userName" name="userName"
                         placeholder="Enter username" autocomplete="off" formControlName="userName"
                         required="" ngModel/>
                  <div class="input-group-append">
                                    <span class="input-group-text text-muted bg-transparent border-left-0">
                                        <em class="fa fa-envelope"></em>
                                    </span>
                  </div>
                </div>
                <div class="text-danger"
                     *ngIf="valForm.controls['userName'].hasError('required') && (valForm.controls['userName'].dirty || valForm.controls['userName'].touched)">
                  This field is required
                </div>
              </div>
              <div class="form-group">
                <div class="input-group with-focus">
                  <input class="form-control border-right-0" id="password" type="password" name="password"
                         placeholder="Password" formControlName="password" required="" ngModel/>
                  <div class="input-group-append">
                                    <span class="input-group-text text-muted bg-transparent border-left-0">
                                        <em class="fa fa-lock"></em>
                                    </span>
                  </div>
                </div>
                <div class="text-danger"
                     *ngIf="valForm.controls['password'].hasError('required') && (valForm.controls['password'].dirty || valForm.controls['password'].touched)">
                  This field is required
                </div>
              </div>
              <div class="clearfix">
                <div class="checkbox c-checkbox float-left mt0">
                  <label>
                    <input type="checkbox" value="" name="account_remember"/>
                    <span class="fa fa-check"></span>Remember Me
                  </label>
                </div>
                <div class="float-right">
                  <a class="text-muted" [routerLink]="'/recover'">Forgot your password?</a>
                </div>
              </div>
              <div class="clearfix">
                <div class="row login-button-div">
                  <app-loading [showIf]="isLoginProcessing">
                    <button class="btn btn-block btn-primary mt-3 login-button" type="submit">Login</button>
                  </app-loading>
                </div>
              </div>
            </div>
            <div class="pt-4 pb-4">
              <hr data-content="OR" class="hr-text">
            </div>
            <div class="clearfix">
              <div class="row login-button-div">
                <img class="login-button" src="assets/img/ms-symbollockup_signin_light.svg"
                     alt="Sign in with Microsoft" (click)="loginWithSso()"/>
              </div>
            </div>
          </form>
        </div>
        <div *ngIf="isSsoLoginProcessing">
          <p class="text-center py-2">{{ssoLoginProccessMessage}}</p>
        </div>
      </div>
    </div>
    <!-- END card-->
    <div class="p-3 text-center">
      <span>&copy;</span>
      <span>{{ settings.app.year }}</span>
      <span class="mx-2">-</span>
      <span>{{ settings.app.name }}</span>
      <br/>
      <span>{{ settings.app.description }}</span>
    </div>
  </div>
</div>
