import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PasswordRecoveryService } from '@app/services/password-recovery/password-recovery.service';
import { SendRecoveryToken } from '@app/models/password-recovery/send-recovery-token.model';
import { SettingsService } from '@app/services/shared/settings.service';
import { Router } from '@angular/router';
import { environment } from "@env/environment.default";

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent {
  public siteKey: string;
  public form: FormGroup;
  public recoveryMailSent: boolean = false;

  constructor(
    public settings: SettingsService,
    private router: Router,
    private passwordRecoveryService: PasswordRecoveryService,
    private formBuilder: FormBuilder) {
    this.siteKey = environment.captchaSiteKey;
    this.form = this.formBuilder.group({
      'email': [null, [Validators.required, Validators.email]],
      'captchaToken': ['', Validators.required]
    });
  }

  public submitForm($event) {
    $event.preventDefault();

    for (let c in this.form.controls) {
      this.form.controls[c].markAsTouched();
    }

    if (this.form.valid) {
      const model: SendRecoveryToken = Object.assign({}, this.form.value);

      this.passwordRecoveryService.sendRecoveryToken(model).subscribe(
        () => {
          this.recoveryMailSent = true;
        },
        (error) => {
          if (error.status == 400) {
            alert(error.error);
            this.router.navigate(["/login"]);
            return;
          }

          this.router.navigate(['/500']);
        }
      );
    }
  }
}
