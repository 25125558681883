import { Injectable } from "@angular/core";

const themeA = require('@app/shared/styles/themes/theme-a.scss');

@Injectable()
export class ThemesService {

    public styleTag: any;
    public defaultTheme: string = 'A';

    constructor() {
        this.createStyle();
        this.setTheme(this.defaultTheme);
    }

    private createStyle() {
        const head = document.head || document.getElementsByTagName('head')[0];
        this.styleTag = document.createElement('style');
        this.styleTag.type = 'text/css';
        this.styleTag.id = 'appthemes';
        head.appendChild(this.styleTag);
    }

    public setTheme(name) {
        switch (name) {
        case 'A':
            this.injectStylesheet(themeA);
            break;
        }
    }

    public injectStylesheet(css) {
        this.styleTag.innerHTML = css;
    }

    public getDefaultTheme() {
        return this.defaultTheme;
    }

}
