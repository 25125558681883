
export enum ProviderCreationTypeEnum {
    DontIssueMobileIdAndInvitation = 1,
    IssueInvitationCodeWithoutCredential = 3,
    IssueInvitationCodeAndCredential = 2,
    IssueInvitationCodeAndPass = 4,
    IssueInvitationCodeAndCredentialAndPass = 5,
    IssuePassInvitation = 6,
}

export const ProviderCreationTypeEnumLabel = new Map<number, string>([
  [ProviderCreationTypeEnum.DontIssueMobileIdAndInvitation, 'Reset password and send to user [no invitation to login]'],
  [ProviderCreationTypeEnum.IssueInvitationCodeWithoutCredential, 'Issue invitation code to enable user to login'],
  [ProviderCreationTypeEnum.IssueInvitationCodeAndCredential, 'Issue invitation code to enable user to login and auto-assign a credential upon login'],
  [ProviderCreationTypeEnum.IssueInvitationCodeAndPass, 'Issue invitation code and pass to enable user to login'],
  [ProviderCreationTypeEnum.IssueInvitationCodeAndCredentialAndPass, 'Issue invitation code to enable user to login and auto-assign a credential and pass upon login'],
  [ProviderCreationTypeEnum.IssuePassInvitation, 'Issue a pass invitation without an invitation code']
]);
