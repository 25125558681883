import { NgModule, Optional, SkipSelf } from '@angular/core';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { SettingsService } from "../services/shared/settings.service";
import { ThemesService } from "../services/shared/themes.service";
import { MenuService } from "@app/services/shared/menu.service";

@NgModule({
    imports: [
    ],
    providers: [
        SettingsService,
        ThemesService,
        MenuService
    ],
    declarations: [
    ],
    exports: [
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
