export const enum NotificationType {
  Ticket = 0,
  Announcement = 1,
  Upload = 2,
  Subscription = 3,
  Administration = 10,
  TaskProggress = 999,
  EmailSendTaskProggress = 1000
}

export const NotificationTypes: Array<any> =
  [
    { text: 'Ticket', id: NotificationType.Ticket },
    { text: 'Announcement', id: NotificationType.Announcement },
    { text: 'Upload', id: NotificationType.Upload },
    { text: 'Subscription', id: NotificationType.Subscription },
    { text: 'Administration', id: NotificationType.Administration },
    { text: 'TaskProggress', id: NotificationType.TaskProggress },
    { text: 'EmailSendTaskProggress', id: NotificationType.EmailSendTaskProggress }
  ];
