import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SendRecoveryToken } from "@app/models/password-recovery/send-recovery-token.model";
import { CheckRecoveryToken } from "@app/models/password-recovery/check-recovery-token.model";
import { RecoverPassword } from "@app/models/password-recovery/recover-password";
import {ClientPasswordPolicy} from "@app/models/client/client.password.policy.model";

@Injectable({ providedIn: "root" })
export class PasswordRecoveryService {
  private service = "PasswordRecoveryService";

  constructor(private http: HttpClient) {
  }

  public sendRecoveryToken(model: SendRecoveryToken) {
    return this.http.post(`api/${this.service}/sendRecoveryToken`, model);
  }

  public checkRecoveryToken(model: CheckRecoveryToken) {
    return this.http.post<ClientPasswordPolicy>(`api/${this.service}/checkRecoveryToken`, model);
  }

  public recoverPassword(model: RecoverPassword) {
    return this.http.post(`api/${this.service}/recoverPassword`, model);
  }
}
