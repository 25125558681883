import { Component, OnInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
    showFooter = true;
    @ViewChild('sectionContainer', { static: true }) sectionContainer: ElementRef;
    constructor(private router: Router, private renderer: Renderer2) {
        this.router.events
            .pipe(
                filter(
                    (event) => {
                        return (event instanceof NavigationEnd);
                    }
                )
            )
            .subscribe(
                (event: NavigationEnd) => {
                    if (event.url && event.url.includes("drawing-helper")) {
                        this.showFooter = false;
                        this.renderer.addClass(this.sectionContainer.nativeElement, "reduce-bottom-margin");
                    } else {
                        this.showFooter = true;
                        this.renderer.removeClass(this.sectionContainer.nativeElement, "reduce-bottom-margin");
                    }
                }
            );
    }

    public ngOnInit() {
    }
}
