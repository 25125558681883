import { Component, OnInit, ViewChild } from '@angular/core';
const screenfull = require('screenfull');
const browser = require('jquery.browser');
declare var $: any;

import { SettingsService } from "../../services/shared/settings.service";
import { UserService } from "../../services/shared/user.service";
import { Router } from '@angular/router';
import { AuthenticatedModel } from "@app/models/authentication/authenticated.model";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public navCollapsed = true; // for horizontal layout
  public menuItems = []; // for horizontal layout

  public isNavSearchVisible: boolean;
  @ViewChild('fsbutton', { static: true })
  public fsbutton; // the fullscreen button

  private authenticatedUser: AuthenticatedModel;

  constructor(public userService: UserService, public settings: SettingsService, private router: Router) {
    // show only a few items on demo
    // this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
  }

  public ngOnInit() {
    this.isNavSearchVisible = false;
    if (browser.msie) { // Not supported under IE
      this.fsbutton.nativeElement.style.display = 'none';
    }

    this.userService.get.subscribe((result) => {
      this.authenticatedUser = result;
    });
  }

  public lockClicked() {
    this.router.navigate(['/lock', { returnUrl: this.router.url }]);
  }

  public toggleUserBlock(event) {
    event.preventDefault();
    this.userService.toggleVisibility();
  }

  public openNavSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(true);
  }

  public setNavSearchVisible(stat: boolean) {
    this.isNavSearchVisible = stat;
  }

  public getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  public toggleOffsidebar() {
    this.settings.layout.offsidebarOpen = !this.settings.layout.offsidebarOpen;
  }

  public toggleCollapsedSideabar() {
    this.settings.layout.isCollapsed = !this.settings.layout.isCollapsed;
  }

  public isCollapsedText() {
    return this.settings.layout.isCollapsedText;
  }

  public toggleFullScreen(event) {

    if (screenfull.enabled) {
      screenfull.toggle().then(() => {
        // Switch icon indicator
        let el = $(this.fsbutton.nativeElement);
        if (screenfull.isFullscreen) {
          el.children('em').removeClass('fa-expand').addClass('fa-compress');
        } else {
          el.children('em').removeClass('fa-compress').addClass('fa-expand');
        }
      });
    }
  }

  isSsoUser() {
    return this.authenticatedUser?.isSsoUser;
  }
}
