import { Injectable } from '@angular/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import swal from 'sweetalert';

@Injectable({
  providedIn: 'root'
})
export class CustomToastrService {

  constructor(private toastrService: ToastrService) { }

  /** show successful toast */
  success(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any>{
    const toastrSuccess = this.toastrService.success(message, title, override);
    toastrSuccess.onTap
    .pipe(take(1))
    .subscribe(() => this.toasterClickedHandler(toastrSuccess, "success"));
    return toastrSuccess;
  }
  /** show error toast */
  error(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any>{
    const toastrError = this.toastrService.error(message, title, override);
    toastrError.onTap
    .pipe(take(1))
    .subscribe(() => this.toasterClickedHandler(toastrError, "error"));
    return toastrError;
  }
  /** show info toast */
  info(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any>{
    const toastrInfo = this.toastrService.info(message, title, override);
    toastrInfo.onTap
    .pipe(take(1))
    .subscribe(() => this.toasterClickedHandler(toastrInfo, "info"));
    return toastrInfo;
  }
  /** show warning toast */
  warning(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any>{
    const toastrWarning = this.toastrService.warning(message, title, override);
    toastrWarning.onTap
    .pipe(take(1))
    .subscribe(() => this.toasterClickedHandler(toastrWarning, "warning"));
    return toastrWarning;
  }

  private toasterClickedHandler(toastr: ActiveToast<any>, type?: string) {
    swal({
      title: toastr.title,
      text: toastr.message,
      icon: type ?? "warning",
      buttons: {
        cancel: {
          text: 'Close',
          visible: true,
          closeModal: true
        },
        confirm: {
          text: 'Copy to Clipboard',
          value: true,
          visible: true,
          className: 'bg-success',
          closeModal: true
        }
      }
    }).then((isConfirm) => {
      if (isConfirm) {
        this.copyMessage(toastr.message);
        this.toastrService.success('Copied to clipboard');
        swal.close?.();
      }
    });
  }

  private copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
