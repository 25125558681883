import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { TokenService } from "@app/services/shared/token.service";
import { UserLockService } from "@app/services/login/user-lock.service";
import { AuthenticationService } from "@app/services/login/authentication.service";
import { AuthorizationLevel } from "@app/core/authorization/authorization-level";
import { Environment } from "@env/environment.global";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { filter, switchMap } from "rxjs/operators";
import { InteractionStatus } from "@azure/msal-browser";
import { Observable, of } from "rxjs";

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private tokenService: TokenService,
    private userLockService: UserLockService,
    private authenticationService: AuthenticationService,
    private environment: Environment,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.tokenService.exists('token')) {
      if (!this.environment.isDevelopment()) {
        var url = this.getRelativeUrlWithoutParameter(state);
        if (!this.authorized(url)) {
          if (url == "reports")//default main page
          {
            //redirect first authorized page
            this.router.navigate([this.getFirstAuthorizedPage()]);
            return true;
          }
          this.router.navigate(["/401"]);
          return false;
        }
      }
      return true;
    }

    this.router.navigate([!this.userLockService.isLocked() ? "/login" : "/lock"], { queryParams: { returnUrl: state.url } });

    return false;
  }
  getRelativeUrlWithoutParameter(state: RouterStateSnapshot) {
    let urlTree = this.router.parseUrl(state.url);
    urlTree.queryParams = {};
    urlTree.fragment = null;
    return urlTree.toString().substring(1);
  }

  private authorized(url: string) {
    var bundles = this.authenticationService.getAuthorizedBundlesFromLocalStorage();
    var authorized = false;
    if (bundles) {
      bundles.forEach(bundle => {
        var pageItem = bundle.pageItems.filter(p => p.authorizationLevelId != AuthorizationLevel.NoAccess && p.authorizationLevelId != AuthorizationLevel.Hidden && url == p.pageItem.url);
        if (pageItem && pageItem != null && pageItem.length > 0) {
          authorized = true;
        }
      });
    }
    return authorized;
  }
  private getFirstAuthorizedPage() {
    var bundles = this.authenticationService.getAuthorizedBundlesFromLocalStorage();
    if (bundles) {
      if (bundles.length > 0) {
        var firstBundleFilter = bundles.filter(b => b.pageItems.filter(p => p.authorizationLevelId != AuthorizationLevel.NoAccess && p.authorizationLevelId != AuthorizationLevel.Hidden).length > 0);
        if (firstBundleFilter.length > 0) {
          var firstBundle = firstBundleFilter[0];
          var bundlePageItem = firstBundle.pageItems.filter(p => p.authorizationLevelId != AuthorizationLevel.NoAccess && p.authorizationLevelId != AuthorizationLevel.Hidden);
          if (bundlePageItem && bundlePageItem.length > 0)
            return "/" + bundlePageItem[0].pageItem.url;
        }
      }
    }
    return "/401";
  }
}
