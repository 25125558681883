import { Observable, throwError } from 'rxjs';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from "@angular/core";
import { TokenService } from "@app/services/shared/token.service";
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import swal from 'sweetalert';
import { Environment } from '@env/environment.global';
import { isNullOrWhitespace } from "@app/shared/utils/string.util";

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(private router: Router,
              private tokenService: TokenService,
              public environment: Environment) {
  }

  handleError(err: HttpErrorResponse): Observable<any> {
    switch (err.status) {
      case undefined: {
        //todo: handle this case
        console.error(err);
        break;
      }
      case 0: {
        swal({
          title: 'Application Error',
          text: "Could not connect to server",
          icon: 'warning',
          buttons: {
            cancel: false,
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              className: 'bg-danger',
              closeModal: true
            }
          }
        });
        break;
      }
      case 400:
      case 500: {
        if(err.error.errors){
          const parsedErrorMessage = Object.values(err.error.errors).flat().join('\n');
          swal({
            title: 'Application Error',
            text: parsedErrorMessage,
            icon: 'warning',
            buttons: {
              cancel: false,
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                className: 'bg-danger',
                closeModal: true
              }
            }
          });
        } else if(err.error && typeof err.error === "object") {
          err.error.text().then((errorMessage: string) => {
            swal({
              title: 'Application Error',
              text: errorMessage,
              icon: 'warning',
              buttons: {
                cancel: false,
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  className: 'bg-danger',
                  closeModal: true
                }
              }
            });
          });
        } else {
          swal({
            title: 'Application Error',
            text: err.error,
            icon: 'warning',
            buttons: {
              cancel: false,
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                className: 'bg-danger',
                closeModal: true
              }
            }
          });
        }
        break;
      }
      case 401:
      case 403: {
        if(err.error && err.error.Error && err.error.Error === 'SessionForcefullyClosed') {
          swal({
            title: 'Session closed',
            text: err.error.Message,
            icon: 'warning',
            buttons: {
              cancel: false,
              confirm: {
                text: 'Ok!',
                value: true,
                visible: true,
                className: 'bg-danger',
                closeModal: true
              }
            }
          });
        } else if(err.error && typeof err.error === "object") {
          err.error.text().then((errorMessage: any) => {
            const parsedErrorMessage = isNullOrWhitespace(errorMessage) ? '' : JSON.parse(errorMessage).Message;

            swal({
              title: 'Authentication Failed',
              text: parsedErrorMessage,
              icon: 'warning',
              buttons: {
                cancel: false,
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  className: 'bg-danger',
                  closeModal: true
                }
              }
            });
          });
        } else {
          const parsedErrorMessage = isNullOrWhitespace(err.error) ? 'Your session is not valid' : err.error;

          swal({
            title: 'Authentication Failed',
            text: parsedErrorMessage,
            icon: 'warning',
            buttons: {
              cancel: false,
              confirm: {
                text: 'Ok!',
                value: true,
                visible: true,
                className: 'bg-danger',
                closeModal: true
              }
            }
          });
        }

        this.tokenService.clear();
        this.router.navigateByUrl(`/login`);

        break;
      }
      default: {
        swal({
            title: 'Application Error',
            text: 'Unexpected error occurred',
            icon: 'warning',
            buttons: {
              cancel: false,
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                className: 'bg-danger',
                closeModal: true
              }
            }
          }
        );
        break;
      }
    }

    return throwError(null);
  }

  handleResponse(event: HttpEvent<any>): void {
    if(event instanceof HttpResponse) {
      if(event.headers.has('newtoken')) {
        var newtoken = event.headers.get('newtoken');
        if(newtoken && newtoken != null) {
          this.tokenService.set('token', newtoken);
          console.log("new token received");
        }
      }
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    let url = !request.url.startsWith('http') ? this.environment.getBaseUrl() + request.url : request.url;

    if(!url.includes("http")) {
      url = document.getElementsByTagName("base").item?.(0)?.href + url;
    }

    if(request &&
      (request.url.indexOf('upload-file') > -1 ||
        request.url.indexOf('upload-image') > -1 ||
        request.url.indexOf('upload-editor-image') > -1 ||
        request.url.indexOf('LoadPdf') > -1 ||
        request.url.indexOf('UploadDataSheet') > -1 ||
        request.url.indexOf('UploadBackgroundImage') > -1 ||
        request.url.indexOf('GenerateDeepZoomImagesWithImage') > -1 ||
        request.url.indexOf('user-admin') > -1)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${ this.tokenService.get('token') }`
        },
        url
      });

      return next.handle(request);
    } else {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${ this.tokenService.get('token') }`,
          'Content-Type': 'application/json; charset=utf-8'
        },
        url
      });
    }

    return next.handle(request).pipe(tap(evt => this.handleResponse(evt))).pipe(catchError(x => this.handleError(x))).pipe();
  }
}
